import { ColumnDef } from "@tanstack/react-table";
import { Download, ResolvedUrl } from "constants/types";
import { Dp, Event, Job, Kp, News, Contact } from "types";
import { unescape } from "helpers/text-processing";

import {
  EntityTableDocumentType,
  EntityTableDownload,
  EntityTableTitle,
} from "components/contentset/entity-table-cells";

export const entity: Array<ColumnDef<ResolvedUrl<Kp>>> = [
  {
    accessorKey: "id",
    meta: {
      hidden: true,
    },
  },
  {
    accessorKey: "title",
    cell: (props) => unescape(props.getValue()),
  },
  {
    accessorKey: "short_title",
    cell: (props) => unescape(props.getValue()),
    meta: {
      hiddenInTable: true,
    },
  },
  {
    accessorKey: "subtitle",
    cell: (props) => unescape(props.getValue()),
  },
  {
    accessorKey: "key_visual",
    meta: {
      hiddenInTable: true,
    },
  },
  {
    accessorKey: "featured_logos",
    meta: {
      hiddenInTable: true,
    },
  },
  {
    accessorKey: "eye_catcher",
    meta: {
      hiddenInTable: true,
    },
  },
  {
    accessorKey: "excerpt",
    meta: {
      hiddenInTable: true,
    },
  },
  {
    accessorKey: "_url",
    meta: {
      hidden: true,
    },
  },
];

export const dlc: Array<ColumnDef<Download>> = [
  {
    accessorKey: "id",
    meta: {
      hidden: true,
    },
  },
  {
    accessorKey: "title",
    header: (props: any) => {
      return (
        <div
          className="th h6"
          style={{
            maxWidth: "55%",
            width: "55%",
          }}
        >
          {props?.attributes?.find((attr) => attr.code === "title")?.label ??
            "Title"}
        </div>
      );
    },
    cell: (props) => <EntityTableTitle {...props} width={55} />,
  },
  {
    accessorKey: "key_visual",
    meta: {
      hidden: true,
    },
  },
  {
    accessorFn: ({ dlc_type }) => dlc_type?.[0].code,
    id: "dlc_type",
    header: (props: any) => (
      <div
        className="th h6"
        style={{
          maxWidth: "15%",
          width: "15%",
        }}
      >
        {props?.attributes?.find((attr) => attr.code === "dlc_type")?.label ??
          "Document Type"}
      </div>
    ),
    cell: (props) => <EntityTableDocumentType {...props} width={15} />,
  },
  {
    accessorKey: "revisions",
    header: () => (
      <div
        className="th h6"
        style={{
          maxWidth: "30%",
          width: "30%",
        }}
      />
    ),
    cell: (props) => <EntityTableDownload {...props} width={30} />,
  },
];

export const preview_news: Array<ColumnDef<ResolvedUrl<News>>> = [
  {
    accessorKey: "id",
    meta: {
      hidden: true,
    },
  },
  {
    accessorKey: "_url",
    meta: {
      hidden: true,
    },
  },
  { accessorKey: "title" },

  { accessorKey: "subtitle" },

  {
    accessorFn: (row) => row.publishing_start_date,
    id: "start_date",
    meta: {
      hiddenInTable: true,
    },
  },
  {
    accessorFn: (row) => row.images,
    id: "key_visual",
    meta: {
      hiddenInTable: true,
    },
  },
  {
    accessorFn: (row) => row.excerpt,
    id: "excerpt",
    meta: {
      hiddenInTable: true,
    },
  },
];

export const dp: Array<ColumnDef<ResolvedUrl<Dp>>> = [
  {
    accessorKey: "id",
    meta: {
      hidden: true,
    },
  },

  {
    accessorKey: "_url",
    meta: {
      hidden: true,
    },
  },

  {
    accessorKey: "key_visual",
    meta: {
      hiddenInTable: true,
    },
  },
];

export const contact: Array<ColumnDef<ResolvedUrl<Contact>>> = [
  {
    accessorKey: "id",
    meta: {
      hidden: true,
    },
  },

  {
    accessorKey: "_url",
    meta: {
      hidden: true,
    },
  },
];

export const list_event: Array<ColumnDef<ResolvedUrl<Event>>> = [
  {
    accessorKey: "id",
    meta: {
      hidden: true,
    },
  },
  {
    accessorKey: "_url",
    meta: {
      hidden: true,
    },
  },
  { accessorKey: "title" },

  {
    accessorFn: (row) =>
      `${row.location}${row.location ? ", " : ""}${
        row.country?.[0]?.label || ""
      }`,
    id: "subtitle",
  },
  {
    accessorFn: (row) => row.excerpt,
    id: "excerpt",
    meta: {
      hiddenInTable: true,
    },
  },
  { accessorFn: (row) => row.images, id: "key_visual" },
];

export const list_news: Array<ColumnDef<ResolvedUrl<News>>> = [
  {
    accessorKey: "id",
    meta: {
      hidden: true,
    },
  },
  {
    accessorKey: "_url",
    meta: {
      hidden: true,
    },
  },
  { accessorKey: "title" },

  { accessorKey: "subtitle" },
  {
    accessorKey: "excerpt",
    meta: {
      hiddenInTable: true,
    },
  },
  {
    accessorFn: (row) => row.publishing_start_date,
    id: "start_date",
    meta: {
      hiddenInTable: true,
    },
  },
  {
    accessorFn: (row) => row.images,
    id: "key_visual",
    meta: {
      hiddenInTable: true,
    },
  },
];

export const list_job: Array<ColumnDef<Job>> = [
  {
    accessorKey: "id",
    meta: {
      hidden: true,
    },
  },
  { accessorKey: "title", cell: (props) => unescape(props.getValue()) },
  {
    accessorFn: (row) => row?.joblink_pdf,
    id: "_url",
    meta: {
      hidden: true,
    },
  },
  {
    accessorFn: (row) =>
      `${row.location_city}${row.location_city ? " | " : ""}${
        row.country?.[0]?.label || ""
      }`,

    id: "subtitle",
  },
];
