import { buildQueryUrl, useLabels } from "helpers/hooks";
import { Button } from "components/button";
import { Card } from "components/card";
import { Col, Row } from "react-bootstrap";
import { Contact, Contentset, Kp_Featured_Logos, Li, Pc } from "types";
import { entitiesConfig, getCallToActionLink } from "config/entity";
import { sanitize, unescape } from "helpers/text-processing";
import { useContentsetContext } from "contexts/contentset-context";
import { useSlugPath } from "helpers/routing";
import classNames from "classnames";
import getConfig from "next/config";
import LoadMore from "../load-more";

import {
  ButtonProps,
  CloudinaryItemProps,
  ContentsetEntity,
  Label,
  Media,
  ViewType,
} from "constants/types";
import ContactCard from "components/card/contact";

const getFeaturedLogos = (
  view: ViewType,
  contentset: Contentset,
  entity: ContentsetEntity,
): CloudinaryItemProps[] => {
  if (
    view.active !== "small_tiles" &&
    contentset.display_keyvisual &&
    "featured_logos" in entity &&
    entity.featured_logos?.length
  ) {
    return (entity.featured_logos as Kp_Featured_Logos[]).map(
      (featuredLogo) => ({
        media: featuredLogo,
      }),
    );
  }
  return null;
};

const getActionButtons = (
  view: ViewType,
  contentset: Contentset,
  url: string,
  label: Label,
  call_to_action?: string,
): ButtonProps[] => {
  if (url && contentset.btn_call_to_action && contentset.link_call_to_action) {
    return [
      {
        // would be wise to use config/entity here - what field?
        ...(contentset.entity_type === "job" && { target: "_blank" }),
        label:
          view.active === "small_tiles" ? label?.short_title : label?.title,
        url: call_to_action !== "lightbox" ? url : null,
        blank: url?.includes("//"),
        ...(contentset.dlc_api === true && { icon: "play-circle" }),
        variant:
          contentset.style_action_button === "transparent"
            ? "white"
            : "primary",
      },
    ];
  }
  return [];
};

export const EntityGrid = ({ isMobile }: { isMobile?: boolean }) => {
  const {
    tableInstance,
    contentset,
    view,
    filters,
    language,
    isLoadingContentsetPage,
    nextPage,
  } = useContentsetContext();
  const path = useSlugPath();
  const { publicRuntimeConfig } = getConfig();

  const entityConfig =
    entitiesConfig[contentset.dlc_api ? "video" : contentset.entity_type] ??
    entitiesConfig._default;
  const {
    preview_image_orientation,
    preview_image_framing,
    preview_image_framing_cl_param,
    preview_image_background,
    preview_image_container_border,
    call_to_action,
    noresult_ui_element,
  } = entityConfig;
  const [callToActionBtn, noResultsBtn, configureBtn] = useLabels(
    [entityConfig?.call_to_action_button?._default, "Details"],
    [
      noresult_ui_element ?? contentset?.noresult_ui_element ?? "ui-585",
      "No results found",
    ],
    ["ui-834", "Configure"],
  );

  const noResultButton = noResultsBtn?.link_relative_path ? (
    <Button
      key={noResultsBtn.link_relative_path}
      label={noResultsBtn.short_title}
      url={noResultsBtn.link_relative_path}
      target="_blank"
      icon="caret-right"
      className="mt-2"
    />
  ) : null;

  const { rows } = tableInstance.getRowModel();

  const isMobileWS = contentset.entity_type === "ws" && isMobile;

  const columnSizes =
    view.map[view.active === "list_thin" ? "list" : view.active];

  const variant =
    view.active === "list"
      ? "horizontal"
      : view.active === "fullscreen_button" || view.active === "list_thin"
      ? "horizontal-thin"
      : "vertical";

  return !isLoadingContentsetPage && !rows.length ? (
    <div className="no-results">
      <p className="label">{noResultsBtn.title}</p>
      {noResultButton}
    </div>
  ) : (
    <>
      <Row
        className={classNames(
          filters.length > 0 && "mt-3",
          "entity-grid",
          `view__${view.active}`,
        )}
      >
        {rows.map((row) => {
          const entity = row.getAllCells().reduce(
            (acc, cell) => ({
              ...acc,
              [cell.column.id]: cell.getValue(),
            }),
            row.original,
          );
          let checkOnlyEnglish = false;
          if (!entityConfig.ignore_different_lang) {
            if (
              ("_lang" in entity && entity._lang !== language) ||
              ("_children" in entity &&
                entity._children?.[0] &&
                entity._children[0]._lang !== language)
            ) {
              checkOnlyEnglish = true;
            } else if ("different_language_detail_view" in entity)
              checkOnlyEnglish = true;
          }

          let url = getCallToActionLink(entity);
          // tried via columns, but we are accessing original here, idk i leave this untouched.
          if (contentset.entity_type === "job" && "joblink_pdf" in entity) {
            url = entity.joblink_pdf;
          }
          const key_visual: Media =
            ("key_visual" in entity && entity?.key_visual?.[0]) ||
            ("images" in entity && entity?.images?.[0]) ||
            ("related_pc" in entity &&
              entity?.related_pc?.[0]?.key_visual?.[0]) ||
            null;

          // get attributes typesafe because some don't exist on every type that is part of ContentsetEntity
          const subtitle = "subtitle" in entity ? entity.subtitle : null;
          const short_title =
            "short_title" in entity ? entity.short_title : null;
          const related_pc: Pc[] =
            "related_pc" in entity ? entity.related_pc : null;
          const excerpt: string = "excerpt" in entity ? entity.excerpt : null;
          const start_date =
            "start_date" in entity ? (entity.start_date as string) : null;
          const end_date =
            "end_date" in entity ? (entity.end_date as string) : null;
          const eye_catcher =
            "eye_catcher" in entity ? entity.eye_catcher : null;

          const isLiWithConfuguration =
            contentset.display_configure_link &&
            "li_configuration" in entity &&
            entity.li_configuration.length;
          return (
            <Col
              xs={columnSizes?.[0] || 12}
              sm={columnSizes?.[1] || 6}
              md={columnSizes?.[2] || 6}
              lg={view.active === "fullscreen_button" ? 6 : columnSizes?.[3] || 4}
              xl={columnSizes?.[4] || 3}
              className={classNames(
                !isMobileWS && "mb-3",
                contentset.glow_over_image && "glow",
              )}
              key={row.id}
            >
              {contentset.layout === "contact_area" &&
              contentset.entity_type === "contact" ? (
                <ContactCard
                  key={`contact-card-${entity.id}`}
                  entity={entity as Partial<Contact & { _url?: string }>}
                />
              ) : (
                <>
                  <Card
                    className={classNames(isMobileWS && "language-card")}
                    date={
                      contentset.display_publishing_date
                        ? {
                            startDate: start_date,
                            endDate: end_date,
                          }
                        : null
                    }
                    key={entity.id}
                    entityId={entity.id}
                    title={
                      (contentset.use_short_title && short_title) ||
                      entity.title ||
                      related_pc?.[0]?.title
                    }
                    cardLabel={
                      contentset.display_keyvisual &&
                      eye_catcher?.length > 0 && {
                        title: eye_catcher[0].label,
                        variant:
                          eye_catcher[0].code === "new"
                            ? "secondary"
                            : "tertiary",
                        position: "left",
                      }
                    }
                    featuredLogos={getFeaturedLogos(view, contentset, entity)}
                    actionButtons={[
                      ...(view.active !== "small_tiles"
                        ? getActionButtons(
                            view,
                            contentset,
                            url,
                            callToActionBtn,
                            call_to_action as string,
                          )
                        : []),
                    ]}
                    cardImg={
                      contentset.display_keyvisual &&
                      key_visual && {
                        media: key_visual,
                        responsiveOrientation:
                          preview_image_orientation === "height"
                            ? "height"
                            : "width",
                        ar: preview_image_framing || "ar43",
                        background: preview_image_background || "none",
                        ...(preview_image_framing !== "none" &&
                          preview_image_framing_cl_param && {
                            overwriteNamedTransformation:
                              preview_image_framing_cl_param,
                          }),
                        ...(preview_image_container_border && {
                          border: preview_image_container_border,
                        }),
                      }
                    }
                    imageWrapperClass={
                      contentset.entity_type === "event" ? "img-height" : ""
                    }
                    onlyEnglish={
                      (!("revisions" in entity) ||
                        entity.different_language_detail_view) &&
                      checkOnlyEnglish
                    }
                    variant={variant}
                    url={
                      contentset.link_call_to_action
                        ? url
                        : isLiWithConfuguration
                        ? entity.li_configuration[0].link_absolute_path
                        : null
                    }
                    externalUrl={
                      contentset.entity_type === "job" ||
                      (contentset.link_call_to_action
                        ? url?.includes("//")
                        : false)
                    }
                    lightbox={contentset.dlc_api ? "video" : null}
                    revisions={"revisions" in entity ? entity.revisions : null}
                  >
                    {contentset.link_call_to_action && Boolean(url) && (
                      <link
                        itemProp="mainEntityOfPage"
                        href={
                          url.includes("http")
                            ? url
                            : publicRuntimeConfig.BASE_URL + url
                        }
                      />
                    )}
                    {view.active === "big_tiles" ? (
                      <div>
                        {Boolean(subtitle || related_pc?.[0]?.subtitle) && (
                          <p
                            className="h6"
                            dangerouslySetInnerHTML={{
                              __html: sanitize(
                                unescape(subtitle || related_pc?.[0]?.subtitle)
                                  .replace(/\n/g, ", ")
                                  .replace(/, $/, ""),
                              ),
                            }}
                          />
                        )}
                        <div
                          className="styled-list-wrapper"
                          dangerouslySetInnerHTML={{
                            __html:
                              excerpt !== "null" && contentset.display_excerpt
                                ? sanitize(
                                    unescape(
                                      excerpt || related_pc?.[0]?.excerpt,
                                    ),
                                  )
                                : null,
                          }}
                        ></div>
                      </div>
                    ) : view.active === "list" ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: sanitize(
                            unescape(subtitle || related_pc?.[0]?.subtitle),
                          ),
                        }}
                      ></p>
                    ) : (
                      view.active !== "fullscreen_button" && (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: sanitize(
                              unescape(subtitle || related_pc?.[0]?.subtitle),
                            ),
                          }}
                        ></p>
                      )
                    )}
                  </Card>
                  {isLiWithConfuguration
                    ? (entity.li_configuration as Li[]).map((li) => (
                        <Button
                          key={li.link_absolute_path}
                          label={configureBtn.title}
                          url={li.link_absolute_path}
                          target="_blank"
                          icon="configure"
                          className="mt-auto"
                        />
                      ))
                    : null}
                </>
              )}
            </Col>
          );
        })}
      </Row>
      <LoadMore
        url={buildQueryUrl(`${contentset.id}-page`, nextPage + 1, 1, path)[0]}
      />
    </>
  );
};
