import { FC, HTMLAttributes } from "react";
import { ShapeProps } from "constants/types";
import classNames from "classnames";
import dynamic from "next/dynamic";

const icons = {
  "3d-exploration": dynamic(() => import("./3d-exploration.svg")),
  "add-to-compare": dynamic(() => import("./add-to-compare.svg")),
  apps: dynamic(() => import("./apps.svg")),
  "big-tiles": dynamic(() => import("./big-tiles.svg")),
  "bookmark-outline": dynamic(() => import("./bookmark-outline.svg")),
  bookmark: dynamic(() => import("./bookmark.svg")),
  calendar: dynamic(() => import("./calendar.svg")),
  "caret-down-circle": dynamic(() => import("./caret-down-circle.svg")),
  "caret-down-circle-filled": dynamic(
    () => import("./caret-down-circle-filled.svg"),
  ),
  "caret-down-double": dynamic(() => import("./caret-down-double.svg")),
  "caret-down-small": dynamic(() => import("./caret-down-small.svg")),
  "caret-down": dynamic(() => import("./caret-down.svg")),
  "caret-left-circle": dynamic(() => import("./caret-left-circle.svg")),
  "caret-left-circle-filled": dynamic(
    () => import("./caret-left-circle-filled.svg"),
  ),
  "caret-left-small": dynamic(() => import("./caret-left-small.svg")),
  "caret-left": dynamic(() => import("./caret-left.svg")),
  "caret-right-circle": dynamic(() => import("./caret-right-circle.svg")),
  "caret-right-circle-filled": dynamic(
    () => import("./caret-right-circle-filled.svg"),
  ),
  "caret-right-small": dynamic(() => import("./caret-right-small.svg")),
  "caret-right": dynamic(() => import("./caret-right.svg")),
  "caret-up-circle": dynamic(() => import("./caret-up-circle.svg")),
  "caret-up-circle-filled": dynamic(
    () => import("./caret-up-circle-filled.svg"),
  ),
  "caret-up-small": dynamic(() => import("./caret-up-small.svg")),
  "caret-up": dynamic(() => import("./caret-up.svg")),
  certificate: dynamic(() => import("./certificate.svg")),
  check: dynamic(() => import("./check.svg")),
  circle: dynamic(() => import("./circle.svg")),
  "close-big": dynamic(() => import("./close-big.svg")),
  "close-circle": dynamic(() => import("./close-circle.svg")),
  close: dynamic(() => import("./close.svg")),
  comment: dynamic(() => import("./comment.svg")),
  configure: dynamic(() => import("./configure.svg")),
  "core-fill": dynamic(() => import("./core-fill.svg")),
  diamond: dynamic(() => import("./diamond.svg")),
  document: dynamic(() => import("./document.svg")),
  "document-types": dynamic(() => import("./document-types.svg")),
  download: dynamic(() => import("./download.svg")),
  disc: dynamic(() => import("./disc.svg")),
  display: dynamic(() => import("./display.svg")),
  "display-2": dynamic(() => import("./display-2.svg")),
  envelope: dynamic(() => import("./envelope.svg")),
  "external-link": dynamic(() => import("./external-link.svg")),
  facebook: dynamic(() => import("./facebook.svg")),
  "facebook-square": dynamic(() => import("./facebook-square.svg")),
  forward: dynamic(() => import("./forward.svg")),
  filter: dynamic(() => import("./filter.svg")),
  "fullscreen-button-open": dynamic(
    () => import("./fullscreen-button-open.svg"),
  ),
  "fullscreen-button": dynamic(() => import("./fullscreen-button.svg")),
  fullscreen: dynamic(() => import("./fullscreen.svg")),
  "framed-check-filled": dynamic(() => import("./framed-check-filled.svg")),
  "framed-check": dynamic(() => import("./framed-check.svg")),
  "framed-shopping-cart-eshop": dynamic(
    () => import("./framed-shopping-cart-eshop.svg"),
  ),
  gear: dynamic(() => import("./gear.svg")),
  globe: dynamic(() => import("./globe.svg")),
  "google-plus-square": dynamic(() => import("./google-plus-square.svg")),
  "graduate-cap": dynamic(() => import("./graduate-cap.svg")),
  "hard-hat-2": dynamic(() => import("./hard-hat-2.svg")),
  "hard-hat": dynamic(() => import("./hard-hat.svg")),
  headset: dynamic(() => import("./headset.svg")),
  home: dynamic(() => import("./home.svg")),
  "level-selector": dynamic(() => import("./level-selector.svg")),
  lightbulb: dynamic(() => import("./lightbulb.svg")),
  image: dynamic(() => import("./image.svg")),
  insights: dynamic(() => import("./insights.svg")),
  "info-circle": dynamic(() => import("./info-circle.svg")),
  info: dynamic(() => import("./info.svg")),
  kakao: dynamic(() => import("./kakao.svg")),
  link: dynamic(() => import("./link.svg")),
  linkedin: dynamic(() => import("./linkedin.svg")),
  "linkedin-square": dynamic(() => import("./linkedin-square.svg")),
  "list-thin-open": dynamic(() => import("./list-thin-open.svg")),
  "list-thin": dynamic(() => import("./list-thin.svg")),
  list: dynamic(() => import("./list.svg")),
  "minus-circle": dynamic(() => import("./minus-circle.svg")),
  "paper-plane": dynamic(() => import("./paper-plane.svg")),
  phone: dynamic(() => import("./phone.svg")),
  "phone-envelope": dynamic(() => import("./phone-envelope.svg")),
  planningtool: dynamic(() => import("./planningtool.svg")),
  play: dynamic(() => import("./play.svg")),
  "play-circle-filled": dynamic(() => import("./play-circle-filled.svg")),
  "play-circle": dynamic(() => import("./play-circle.svg")),
  "plus-circle": dynamic(() => import("./plus-circle.svg")),
  plus: dynamic(() => import("./plus.svg")),
  "plus-2": dynamic(() => import("./plus-2.svg")),
  print: dynamic(() => import("./print.svg")),
  product: dynamic(() => import("./product.svg")),
  pick: dynamic(() => import("./pick.svg")),
  "pick-2": dynamic(() => import("./pick-2.svg")),
  qzone: dynamic(() => import("./qzone.svg")),
  relations: dynamic(() => import("./relations.svg")),
  scroll: dynamic(() => import("./scroll.svg")),
  "search-minus": dynamic(() => import("./search-minus.svg")),
  "search-plus": dynamic(() => import("./search-plus.svg")),
  search: dynamic(() => import("./search.svg")),
  "shopping-cart-eshop": dynamic(() => import("./shopping-cart-eshop.svg")),
  "shopping-cart": dynamic(() => import("./shopping-cart.svg")),
  "side-menu": dynamic(() => import("./side-menu.svg")),
  "sina-weibo": dynamic(() => import("./sina-weibo.svg")),
  "small-tiles": dynamic(() => import("./small-tiles.svg")),
  "spareparts-repair": dynamic(() => import("./spareparts-repair.svg")),
  "step-backward": dynamic(() => import("./step-backward.svg")),
  "step-forward": dynamic(() => import("./step-forward.svg")),
  stop: dynamic(() => import("./stop.svg")),
  tag: dynamic(() => import("./tag.svg")),
  trainings: dynamic(() => import("./trainings.svg")),
  twitter: dynamic(() => import("./twitter.svg")),
  "twitter-square": dynamic(() => import("./twitter-square.svg")),
  user: dynamic(() => import("./user.svg")),
  vcard: dynamic(() => import("./vcard.svg")),
  "vk-square": dynamic(() => import("./vk-square.svg")),
  wechat: dynamic(() => import("./wechat.svg")),
  "wechat-square": dynamic(() => import("./wechat-square.svg")),
  windowed: dynamic(() => import("./windowed.svg")),
  "xing-square": dynamic(() => import("./xing-square.svg")),
  youtube: dynamic(() => import("./youtube.svg")),
  "youtube-square": dynamic(() => import("./youtube-square.svg")),
  "zoom-video": dynamic(() => import("./zoom-video.svg")),
} as const;

export type Icon = keyof typeof icons;

export const Shape: FC<HTMLAttributes<HTMLSpanElement> & ShapeProps> = ({
  variant,
  fill = null,
  size = 18,
  className,
  ...props
}): JSX.Element | null => {
  if (!Object.keys(icons).includes(variant)) {
    console.warn(
      `Shape: Icon variant "${variant}" was tried to be rendered, but it does not exist.`,
    );
    return null;
  }

  const Icon = icons[variant];

  if (!Icon) {
    return null;
  }

  return (
    <span
      className={classNames("svg", variant, className)}
      style={{
        display: "flex",
        placeItems: "center",
        alignItems: "center",
        justifyContent: "center",
        flexShrink: 0,
        flexGrow: 0,
      }}
      {...props}
    >
      <Icon fill={fill || "#E5EFF6"} style={{ width: size, height: size }} />
    </span>
  );
};
