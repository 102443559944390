import { Button } from "components/button";
import { Shape } from "components/shape";
import { useLabels } from "helpers/hooks";

const ConfirmationPopup = ({ cancelClose, confirmClose }) => {
  const [closeWindow, cancelAction, attentionHeader, confirmationText] = useLabels(["ui-5", "Close window"], ["ui-3", "Cancel action"], ["ui-992", "Attention"], ["ui-993", "If you close the form, all previous changes will be lost. Do you really want to close the form?"]);


  return (
    <div className="confirmation-popup">
      <div className="confirmation-popup-wrapper">
        <div className="header">
          <span>{attentionHeader.label}</span>
          <Shape variant="close-big" size={14} onClick={cancelClose} />
        </div>
        <div className="content">
          <p>{confirmationText.label}</p>
          <div className="button-wrapper">
            <Button variant="primary" label={closeWindow.label} onClick={confirmClose} />
            <Button variant="white" label={cancelAction.label} onClick={cancelClose} />
          </div>

        </div>

      </div>
    </div>
  )
}

export default ConfirmationPopup;
