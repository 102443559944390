import { Shape } from "components/shape";
import { ProcessedFilter } from "constants/types";
import { useContentsetContext } from "contexts/contentset-context";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { colors } from "theme/theme";
import omit from "lodash/omit";
import classNames from "classnames";

const SEARCH_KEY = "zip";

type ZipFilterProps = {
  filter: ProcessedFilter;
  input?: string;
  onInputChange?: (input: string) => void;
  onSearch?: () => void;
};

export const ZipFilter = forwardRef<HTMLInputElement, ZipFilterProps>(
  function SearchFilter({ filter, input, onInputChange, onSearch }, ref) {
    const {
      searchInput,
      setSearchInput,
      filterAndLoadMore,
    } = useContentsetContext();

    const [localInput, setLocalInput] = useState(input || searchInput[SEARCH_KEY] || "");

    const debouncedSearch = useMemo(
      () =>
        debounce((value: string) => {
          if (onInputChange) {
            onInputChange(value || "");
          } else {
            if (value) {
              setSearchInput({ ...cloneDeep(searchInput), [SEARCH_KEY]: value });
            } else {
              setSearchInput(omit(searchInput, [SEARCH_KEY]));
            }
          }

          onSearch?.();
          void filterAndLoadMore(0);
        }, 300),
      [onInputChange, onSearch, setSearchInput, searchInput, filterAndLoadMore],
    );

    useEffect(() => {
      return () => {
        debouncedSearch.cancel();
      };
    }, [debouncedSearch]);

    const handleInputChange = useCallback(
      (value: string) => {
        setLocalInput(value);
        debouncedSearch(value);
      },
      [debouncedSearch],
    );

    const handleSearch = useCallback(() => {
      debouncedSearch.cancel();
      if (onSearch) {
        onSearch();
      }
      void filterAndLoadMore(0);
    }, [debouncedSearch, onSearch, filterAndLoadMore]);

    const handleClear = useCallback(() => {
      setLocalInput("");
      setSearchInput(omit(searchInput, [SEARCH_KEY]));
      void filterAndLoadMore(0);
    }, [setSearchInput, searchInput, filterAndLoadMore]);

    return (
      <div
        className={classNames(
          "search-filter ZIP",
          searchInput?.[SEARCH_KEY] && "search-filter--active",
        )}
      >
        <Shape
          variant="search"
          fill={colors.primary}
          className="search-filter__icon"
          onClick={handleSearch}
        />
        <input
          className="search-filter__input"
          value={localInput}
          onChange={(event) => handleInputChange(event.target.value)}
          placeholder={filter?.label}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSearch();
            }
          }}
          ref={ref}
        />
        <Shape
          variant="close"
          fill={colors.gray60}
          className="search-filter__icon search-filter__icon--clear"
          onClick={handleClear}
        />
      </div>
    );
  },
);
