import { LabelContextProps } from "constants/types";
import { FC, ReactNode, createContext, useContext, useMemo } from "react";

export const LabelContext = createContext<LabelContextProps>(null);

export const LabelContextProvider: FC<{
  children: ReactNode;
  labels: LabelContextProps;
}> = ({ labels, children }) => {
  const memoizedLabels = useMemo(() => labels, [labels]);

  return (
    <LabelContext.Provider value={memoizedLabels}>
      {children}
    </LabelContext.Provider>
  );
};

export const useLabelContext = () => {
  const context = useContext(LabelContext);
  if (!context) {
    throw new Error("useLabelContext must be used within LabelContextProvider");
  }
  return context;
};
