import { Cloudinary } from "components/cloudinary";
import { Container } from "components/container";
import { Heading } from "components/heading";
import { Shape } from "components/shape";
import { LightboxProps } from "constants/types";
import {
  FC,
  HTMLAttributes,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { colors } from "theme/theme";

import classNames from "classnames";
import { shameScroll } from "helpers/shame-scroll";
import { createPortal } from "react-dom";
import { Transition } from "react-transition-group";
import { uniqueId } from "lodash";
import ConfirmationPopup from "./confirmation-popup/confirmation-popup";

export interface IPortal {
  selector?: string;
}

const Portal = (props: PropsWithChildren<IPortal>) => {
  props = {
    selector: "__ROOT_PORTAL__",
    ...props,
  };

  const { selector, children } = props;

  const ref = useRef<Element>();
  const [mounted, setMounted] = useState(false);

  const selectorPrefixed = "#" + selector.replace(/^#/, "");

  useEffect(() => {
    ref.current = document.querySelector(selectorPrefixed);

    if (!ref.current) {
      const div = document.createElement("div");
      div.setAttribute("id", selector);
      document.body.appendChild(div);
      ref.current = div;
    }

    setMounted(true);
  }, [selector, selectorPrefixed]);

  return mounted ? createPortal(children, ref.current) : null;
};

export default Portal;

export const Lightbox: FC<LightboxProps & HTMLAttributes<HTMLDivElement>> = ({
  open,
  setOpen,
  heading,
  headingType = "primary",
  size = "small",
  background = "white",
  wrapperClassName = "",
  className = "",
  children,
  disableAnimation = false,
  switchHandler,
  disableNavigation = { prev: false, next: false },
}) => {
  const [id, setId] = useState(uniqueId("lightbox_"));
  const [showConfirmation, setShowConfirmation] = useState(false);
  const isFormDirtyRef = useRef(false);
  const lightboxRef = useRef<HTMLDivElement>(null);

  const isSmall = size === "small";
  const firstRender = useRef(true);
  const transitionRef = useRef<HTMLDivElement>(null);

  // Add default close button
  if (heading && !heading?.contentRight && setOpen) {
    heading.contentRight = (
      <div
        onClick={() => {
          handleClose();
        }}
        className="lightbox-close"
      >
        <Shape
          variant="close-big"
          fill={headingType !== "white" ? colors.white : colors.gray100}
        />
      </div>
    );
  }

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (open) shameScroll({ open: true, id: id });
    else shameScroll({ close: true, id: id });
  }, [id, open]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 27) {
      handleClose();
    }
  };

  useEffect(() => {
    window.document.addEventListener("keydown", handleKeyDown);
    return () => {
      window.document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleClose = useCallback(() => {
    if (isFormDirtyRef.current) {
      setShowConfirmation(true);
    } else {
      setOpen(false);
    }
  }, [setOpen]);

  const confirmClose = useCallback(() => {
    isFormDirtyRef.current = false;
    setShowConfirmation(false);
    setOpen(false);
  }, [setOpen]);

  const cancelClose = useCallback(() => {
    setShowConfirmation(false);
  }, []);

  useEffect(() => {
    const lightboxElement = lightboxRef.current;
    if (!lightboxElement) return;

    if (open) isFormDirtyRef.current = false;

    const handleInputChange = (event: Event) => {
      const target = event.target as
        | HTMLInputElement
        | HTMLTextAreaElement
        | HTMLSelectElement;

      // Check if the target is inside an element with the class "search-lightbox-content"
      // and exclude confirmation functions for Lightbox-Search
      const isInsideSearchContent = target.closest(".search-lightbox-content");
      if (isInsideSearchContent) {
        return;
      }

      if (
        target &&
        (target.tagName === "INPUT" ||
          target.tagName === "TEXTAREA" ||
          target.tagName === "SELECT")
      ) {
        isFormDirtyRef.current = true;
      }
    };

    lightboxElement.addEventListener("input", handleInputChange);
    lightboxElement.addEventListener("change", handleInputChange);

    return () => {
      lightboxElement.removeEventListener("input", handleInputChange);
      lightboxElement.removeEventListener("change", handleInputChange);
    };
  }, [open]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== "https://forms.krohne.com") {
        console.warn("Untrusted message origin:", event.origin);
        return;
      }

      if (event.data?.type === "form-dirty") {
        isFormDirtyRef.current = true;
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <>
      <Portal>
        <Transition
          in={open}
          nodeRef={transitionRef}
          mountOnEnter
          unmountOnExit
          appear
          timeout={300}
        >
          {(state) => (
            <div className={wrapperClassName} ref={transitionRef}>
              <div
                className={classNames(
                  "lightbox",
                  className,
                  isSmall && "lightbox-small",
                  state && `lightbox-${state}`,
                  disableAnimation && "lightbox-noAnimation",
                )}
                /*  onClick={() => {
                  handleClose();
                }} */
              >
                <div
                  className={`lightbox-window ${size}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {heading && (
                    <div className={`lightbox-heading ${headingType}`}>
                      {isSmall ? (
                        <Heading {...heading} />
                      ) : (
                        <Container>
                          <Heading {...heading} />
                        </Container>
                      )}
                    </div>
                  )}
                  <div
                    className={classNames(
                      "lightbox-content",
                      background === "white" && "white",
                    )}
                    ref={lightboxRef}
                  >
                    {children}
                    {background === "grey-triangle" && (
                      <Cloudinary
                        className="lightbox-background h-full"
                        media={{
                          digital_asset_id: "im-background/grey-triangle-4-3",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Transition>
      </Portal>

      {/* Confirmation Popup */}
      {showConfirmation && (
        <ConfirmationPopup
          confirmClose={confirmClose}
          cancelClose={cancelClose}
        />
      )}
    </>
  );
};
