import _debounce from "lodash/debounce";
import { Button } from "components/button";
import { CloudinaryProps, SelectOption } from "constants/types";
import { Col, Container, Row } from "react-bootstrap";
import { getCountryAndLanguage } from "helpers/locale/utils";
import { isLandingpage as checkLandingpage } from "helpers/routing/utils";
import { kebabCase } from "lodash";
import { LocationDataResponse } from "pages/api/locationdata";
import { Select } from "components/forms/select";
import { useCachedFetch, useLabels } from "helpers/hooks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocale } from "helpers/locale";
import { useNavContext } from "contexts/nav-context";
import { useSlugPath } from "helpers/routing";
import { Ws } from "types";
import React from "react";

export type Country = Pick<
  Ws,
  | "available_language"
  | "country"
  | "default_language"
  | "id"
  | "title"
  | "website_protocol_and_hostname"
>;

export type Countries = {
  entities?: Country[];
};

export type WebsiteSelectOption = {
  country: Country;
  image: CloudinaryProps;
  label: string;
  url: string;
  value: string;
};

type CountryForwardingProps = {
  entityId?: string;
};

const CountryForwarding = ({ entityId }: CountryForwardingProps) => {
  const currentLocale = useLocale();
  const [continueBtn] = useLabels(["ui-915", "Continue"]);
  const [copy] = useLabels([
    "ui-900",
    "This page differs from detected location",
  ]);
  const [stayOnBtn] = useLabels(["ui-902", "Stay on"]);
  const [countries]: Array<Countries> = useCachedFetch(
    kebabCase("/api-countries"),
    () => fetch("/api/countries").then((res) => res.json()),
  );

  const [locationData]: [LocationDataResponse, (_) => void] = useCachedFetch(
    kebabCase("/api/locationdata"),
    () => fetch("/api/locationdata").then((res) => res.json()),
    // 3 month
    60 * 60 * 24 * 30 * 3,
  );
  const [showBanner, setShowBanner] = useState(false);
  const [selectedValue, setSelectedValue] = useState<WebsiteSelectOption>(null);
  const [defaultValue, setDefaultValue] = useState<WebsiteSelectOption>(null);
  const [currentCountryTitle, setCurrentCountryTitle] = useState("");

  const selectedCountry =
    selectedValue?.country?.country?.[0]?.code ||
    defaultValue?.country?.country?.[0]?.code ||
    currentLocale.country;
  const selectedLocale =
    selectedValue?.country?.default_language?.[0]?.code?.split("_")?.[0] ||
    defaultValue?.country?.default_language?.[0]?.code?.split("_")?.[0] ||
    currentLocale.language;

  const params = new URLSearchParams({
    entityId,
    country: selectedCountry,
    language: selectedLocale,
  }).toString();

  const [targetUrl, setTargetUrl] = useState<string | null>(null);
  const targetAbortController = useRef<AbortController | null>(null);

  useEffect(() => {
    if (!showBanner) {
      document.body.style.setProperty("--country-banner-height", "0px");
      return;
    }

    setTargetUrl(null);
    targetAbortController.current?.abort();
    targetAbortController.current = new AbortController();

    void (async () => {
      try {
        const request = await fetch(`/api/generate-route?${params}`, {
          signal: targetAbortController.current?.signal,
        });
        if (!request.ok) {
          return;
        }
        const newTargetUrl = await request.text();
        setTargetUrl(newTargetUrl);
      } catch (error) {
        console.error("Failed to fetch:", error);
      }
    })();
  }, [params, showBanner]);

  const slugPath = useSlugPath();
  const isLandingpage = checkLandingpage(slugPath);

  let preferredWebSetting = undefined;

  if (typeof window !== "undefined") {
    try {
      preferredWebSetting = localStorage.getItem("preferred_web");
    } catch (error) {
      preferredWebSetting = undefined;
    }
  }

  const initializeDefaultValue = useCallback(() => {
    if (countries?.entities) {
      // uncomment the line below to get banner working on localhost
      /* locationData.country_code = "de"; */

      if (!locationData?.country_code) return;

      const preferredCountry = countries.entities
        .filter((y) => "country" in y)
        .find((x) => x.country?.[0]?.code === locationData.country_code);

      if (!preferredCountry) return;

      const userCountry = locationData.country_code;
      const pageCountry = currentLocale.country;

      if (userCountry === pageCountry) return;

      const defaultLanguage = preferredCountry.available_language.find(
        (lang) => {
          const [country] = getCountryAndLanguage(lang.code).map((str) =>
            str.toLowerCase(),
          );
          const [defaultCountry] = getCountryAndLanguage(
            preferredCountry.default_language[0].code,
          ).map((str) => str.toLowerCase());
          if (country === defaultCountry) return lang;
        },
      );
      const [country] = getCountryAndLanguage(defaultLanguage.code);
      setDefaultValue({
        label: `${preferredCountry.title} / ${
          defaultLanguage.label?.split("/")[0]?.split("(")[0] || ""
        }`,
        value: preferredCountry.id,
        image:
          "key_visual" in preferredCountry
            ? { media: preferredCountry.key_visual?.[0], width: 18, height: 18 }
            : null,
        url: `${preferredCountry.website_protocol_and_hostname}/${country}`,
        country: preferredCountry,
      });
      setShowBanner(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries?.entities, currentLocale.country, locationData?.country_code]);

  useEffect(() => {
    // uncomment these lines to get banner working on localhost
    /* setShowBanner(true);
    initializeDefaultValue(); */

    const browserLanguage = navigator.language
      .split("-")[0]
      .toLocaleLowerCase();

    if (countries?.entities && locationData?.country_code) {
      const currentCountryTitle: string = countries.entities
        .filter((e) => e.country)
        .find((en) => en.country[0].code === currentLocale.country)?.title;

      setCurrentCountryTitle(
        currentLocale.country === "k"
          ? currentCountryTitle
          : `KROHNE ${currentCountryTitle}`,
      );

      if (!preferredWebSetting) {
        if (
          currentLocale.country === locationData.country_code &&
          currentLocale.language === browserLanguage
        ) {
          setShowBanner(false);
        } else {
          initializeDefaultValue();
        }
      } else {
        const is_preferred_web_closed =
          JSON.parse(preferredWebSetting).isClosed;

        if (
          (currentLocale.country !== locationData.country_code ||
            currentLocale.language !== browserLanguage) &&
          !is_preferred_web_closed
        ) {
          initializeDefaultValue();
        } else {
          setShowBanner(false);
        }
      }
    }

    if (isLandingpage) {
      setShowBanner(false);
    }
  }, [
    countries,
    currentLocale.country,
    currentLocale.language,
    initializeDefaultValue,
    isLandingpage,
    locationData,
    preferredWebSetting,
    slugPath,
  ]);

  const { setShowCountryForwardingBanner } = useNavContext();
  const countryBannerElement = useRef<HTMLDivElement>(null);

  const checkBannerVisibility = useCallback(() => {
    const bannerElement = countryBannerElement.current;
    if (!bannerElement) return;

    const { top, bottom, height } = bannerElement.getBoundingClientRect();
    const visibleHeight = Math.ceil(
      Math.max(0, Math.min(height, bottom) - Math.max(0, top)),
    );
    const isVisible = top < window.innerHeight && bottom >= 0;

    if (typeof document !== "undefined") {
      document.body.style.setProperty(
        "--country-banner-height",
        `${isVisible ? visibleHeight : "0"}px`,
      );
    }
  }, []);

  useEffect(() => checkBannerVisibility(), [checkBannerVisibility]);

  const debouncedHandleScroll = useMemo(
    () => _debounce(() => checkBannerVisibility(), 200),
    [checkBannerVisibility],
  );

  useEffect(() => {
    setShowCountryForwardingBanner(showBanner);
  }, [setShowCountryForwardingBanner, showBanner]);

  useEffect(() => {
    debouncedHandleScroll();
    window.addEventListener("scroll", debouncedHandleScroll);
    window.addEventListener("resize", debouncedHandleScroll);

    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
      window.removeEventListener("resize", debouncedHandleScroll);
    };
  }, [debouncedHandleScroll]);

  const memoizedOptions = useMemo(() => {
    if (!countries?.entities || !defaultValue) return [];

    return countries.entities
      .filter(
        ({ country }) =>
          Boolean(country?.[0]) &&
          country[0].code !== defaultValue.country.country[0].code,
      )
      .map((country) => {
        // TODO: The browser language should be checked here
        const defaultLocale = country.available_language.find(
          (item) => item.code === country.default_language[0].code,
        );
        const languageLabel = defaultLocale.label.split("/")[0].split("(")[0];
        const [language] = getCountryAndLanguage(defaultLocale?.code);

        return {
          label: `${country.title} / ${languageLabel}`,
          value: country.id,
          country,
          image: {
            media:
              "key_visual" in country
                ? country.key_visual?.[0]
                : { digital_asset_id: "im-background/grey-triangle-1-1" },
            width: 18,
            height: 18,
          },
          url: `${country.website_protocol_and_hostname}/${language}`,
        };
      });
  }, [countries?.entities, defaultValue]);

  const handleSelectChange = useCallback((newValue: WebsiteSelectOption) => {
    setSelectedValue(newValue);
  }, []);

  return (
    <>
      {showBanner && entityId ? (
        <div
          id="country-forwarding"
          className="bg-secondary pt-1 pb-2 pb-md-1"
          ref={countryBannerElement}
        >
          <Container fluid="xl" className="">
            <Row className="align-items-center justify-content-between">
              <Col
                className="text-white mb-2 mb-md-0 "
                sm={12}
                md={5}
                lg={6}
                xl={7}
              >
                <div style={{ fontWeight: 500 }}>{copy.short_title}</div>
                <div className="small">{copy.title}</div>
              </Col>
              <Col sm={12} md={7} lg={6} xl={5}>
                <div className="d-block d-sm-flex align-items-center">
                  <div className="flex-fill pr-0 pr-sm-1">
                    {countries?.entities && defaultValue && (
                      <Select
                        value={selectedValue || defaultValue}
                        options={[
                          {
                            options: [defaultValue as SelectOption], // Suggested
                            label: "",
                          },
                          { options: memoizedOptions, label: "" }, // All
                        ]}
                        className="country-forwarding-select"
                        id="country-forwarding-select"
                        handleChange={handleSelectChange}
                      />
                    )}
                  </div>
                  <div className="d-flex align-items-center mt-2 mt-sm-0">
                    <div className="mr-1">
                      {(defaultValue || selectedValue) && (
                        <Button
                          label={continueBtn.short_title}
                          variant="salmon-outline"
                          icon="caret-right-small"
                          url={
                            targetUrl ||
                            (selectedValue
                              ? selectedValue.url
                              : defaultValue.url)
                          }
                          onClick={() => {
                            localStorage?.setItem(
                              "preferred_web",
                              JSON.stringify({
                                country: defaultValue.country.country,
                                url: defaultValue.url,
                                isClosed: true,
                              }),
                            );

                            setShowBanner(false);
                          }}
                          className="m-0"
                        />
                      )}
                    </div>
                    <div
                      className="text-white small stay-on-btn"
                      style={{ cursor: "pointer", fontWeight: 500 }}
                      onClick={() => {
                        localStorage?.setItem(
                          "preferred_web",
                          JSON.stringify({
                            language: currentLocale.language,
                            country: currentLocale.country,
                            isClosed: true,
                          }),
                        );
                        setShowBanner(false);
                      }}
                    >
                      <span>{`${stayOnBtn.title} ${currentCountryTitle}`}</span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ) : null}
    </>
  );
};

export default React.memo(CountryForwarding);
