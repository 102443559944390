import { Card } from "components/card";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { breakpoints, colors } from "theme/theme";
import { has, isEmpty } from "lodash-es";
import { Icon, Shape } from "components/shape";
import { parseJson } from "helpers/utils";
import { Search } from "components/search2";
import { shameScroll } from "helpers/shame-scroll";
import { useLabels, usePrevious } from "helpers/hooks";
import { useLocale } from "helpers/locale";
import { useNavContext } from "contexts/nav-context";
import { useSlugPath } from "helpers/routing";
import classNames from "classnames";
import getConfig from "next/config";
import LanguagePortal from "components/languages/language-portal";
import Link from "components/link";
import Logo from "./logo";
import nav_settings from "config/navigation";
import Router, { useRouter } from "next/router";
import WebApps from "components/_layout/page/web-apps";

import {
  FormattedPageCards,
  MetaNav,
  PageProps,
  PageTree,
} from "constants/types";

import {
  FC,
  MouseEvent,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  CSSTransition,
  SwitchTransition,
  Transition,
  TransitionStatus,
} from "react-transition-group";
import { getSpeakingId } from "helpers/text-processing";
import { useMediaQuery } from "react-responsive";

const getMenuPageTreeChildren = (pageTree: PageTree) => {
  if (!pageTree?.children) return [];
  return Object.values(pageTree.children).filter((child) => child.show_in_menu);
};

const hasMenuPageTreeChildren = (pageTree: PageTree) => {
  if (!pageTree?.children) return false;
  return Object.values(pageTree.children).some((child) => child.show_in_menu);
};

type NavigationTreeProps = {
  isActive?: boolean;
  onMinHeightChange?: (minHeight: number | null) => void;
  onOpenChildChange?: (childId: string | null) => void;
  root: PageTree;
};

const NavigationTree: FC<NavigationTreeProps> = ({
  isActive = false,
  root,
  onMinHeightChange,
  onOpenChildChange,
}) => {
  const [openChild, setOpenChildId] = useState<string | null>(null);
  const [minHeight, setMinHeight] = useState(null);
  const internalMinHeight = useRef<number | null>(null);
  const childrenRef = useRef<{ [childId: string]: HTMLLIElement }>({});
  const [overviewBtn] = useLabels(["ui-19", "Overview"]);

  const setOpenChild = useCallback((childId: string | null) => {
    setOpenChildId(childId);
    const newMinHeight = childId
      ? childrenRef.current[childId].querySelector<HTMLUListElement>(
        ":scope > .navigationTree",
      )?.offsetHeight
      : null;
    internalMinHeight.current = newMinHeight;
    setMinHeight(newMinHeight);
    onMinHeightChange?.(newMinHeight);
    onOpenChildChange?.(childId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isActive) {
      setOpenChild(null);
    }
  }, [isActive, setOpenChild]);

  return (
    root.show_in_menu && (
      <ul className="navigationTree" style={{ minHeight }}>
        <li className="navigationTree-item-box border-bottom-skyblue bold">
          <Link href={root.url} prefetch={false} className="d-block">
            {`${root.title} – ${overviewBtn?.label}`}
          </Link>
        </li>
        {root.children &&
          getMenuPageTreeChildren(root)?.map((child) => {
            const hasChildren = hasMenuPageTreeChildren(child);
            const isOpen = child.id === openChild;

            return (
              <li
                key={child.id}
                className={classNames(
                  "navigationTree-item",
                  isOpen && hasChildren && "active",
                )}
                ref={(ref) => (childrenRef.current[child.id] = ref)}
              >
                {child.external ? (
                  <a
                    className="navigationTree-item-box border-bottom-skyblue flex-between-center"
                    href={child.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="nav-item">
                      <span className="d-flex">
                        <Shape
                          variant={"external-link"}
                          fill={colors.darkBlue}
                        />
                        <div className="ml-1">
                          {child.navigation_title ?? child.title}
                        </div>
                      </span>
                    </div>
                  </a>
                ) : (
                  <Link href={child.url} prefetch={false} legacyBehavior>
                    <a
                      className="navigationTree-item-box border-bottom-skyblue flex-between-center"
                      onClick={(e) => {
                        if (hasChildren) {
                          setOpenChild(child.id);
                          if (!isOpen) e.preventDefault();
                        }
                      }}
                    >
                      <div
                        className={classNames("label", isOpen && "bold")}
                        dangerouslySetInnerHTML={{
                          __html: child.navigation_title ?? child.title,
                        }}
                      />
                      {hasChildren && (
                        <Shape
                          variant={"caret-right"}
                          fill="currentColor"
                          size={16}
                        />
                      )}
                    </a>
                  </Link>
                )}
                {hasChildren && (
                  <NavigationTree
                    isActive={isActive}
                    root={child}
                    onMinHeightChange={(subMinHeight) => {
                      setMinHeight(
                        Math.max(
                          subMinHeight ?? 0,
                          internalMinHeight.current ?? 0,
                        ),
                      );
                    }}
                  />
                )}
              </li>
            );
          })}
      </ul>
    )
  );
};

type MegaMenuProps = {
  activeNavigationItem: PageTree;
  navigationItems: PageTree[];
  setMegaMenuOpen: (open: boolean) => void;
  transitionStatus: TransitionStatus;
};

const MegaMenu = forwardRef<HTMLDivElement, MegaMenuProps>(
  (
    {
      navigationItems,
      activeNavigationItem,
      setMegaMenuOpen,
      transitionStatus,
    },
    ref,
  ) => {
    const [isMenuChildOpen, setIsMenuChildOpen] = useState(false);
    const previous = usePrevious({ activeNavigationItem });
    const menuWrappers = useRef<{ [id: string]: HTMLDivElement }>({});
    const heightWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleClick = (e: globalThis.MouseEvent) => {
        if (!(e.target instanceof HTMLElement)) return;

        const clickedOnScrollbar =
          e.offsetX > e.target.clientWidth || e.offsetY > e.target.clientHeight;
        const clickedInsideMenu =
          typeof ref !== "function" && ref?.current?.contains(e.target);
        const clickedOnNavLink = e.target?.classList.contains("nav-link");

        if (clickedInsideMenu || clickedOnNavLink || clickedOnScrollbar) {
          return;
        }

        // outside click
        setMegaMenuOpen(false);
      };

      document.addEventListener("mousedown", handleClick);
      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    }, [ref, setMegaMenuOpen]);

    const animateHeight = useCallback(
      (oldHeight: number, newHeight: number) => {
        if (!heightWrapperRef.current) {
          return;
        }

        const newNavigationId = activeNavigationItem.id;
        const newNavigationWrapper = menuWrappers.current[newNavigationId];

        heightWrapperRef.current.style.height = `${oldHeight}px`;
        heightWrapperRef.current.style.overflow = "hidden";
        newNavigationWrapper.style.overflow = "visible";
        // double requestAnimationFrame for firefox
        window.requestAnimationFrame(() =>
          window.requestAnimationFrame(() => {
            heightWrapperRef.current.style.height = `${newHeight}px`;
            setTimeout(() => {
              heightWrapperRef.current.style.height = "";
              heightWrapperRef.current.style.overflow = "";
              newNavigationWrapper.style.overflow = "";
            }, 250);
          }),
        );
      },
      [activeNavigationItem],
    );

    useEffect(() => {
      setIsMenuChildOpen(false);

      const previousNavigationId = previous?.activeNavigationItem.id;
      const previousNavigationWrapper =
        menuWrappers.current[previousNavigationId];
      const previousNavigationHeight = previousNavigationWrapper?.clientHeight;
      const newNavigationId = activeNavigationItem.id;
      const newNavigationWrapper = menuWrappers.current[newNavigationId];
      const newNavigationHeight = newNavigationWrapper?.clientHeight;

      if (!previousNavigationWrapper || !newNavigationWrapper) {
        return;
      }

      animateHeight(previousNavigationHeight, newNavigationHeight);

      // don't trigger on previous value change
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeNavigationItem]);

    return (
      <>
        <div className={classNames("mega-menu", transitionStatus)} ref={ref}>
          <div className="mega-menu__height-wrapper" ref={heightWrapperRef}>
            <Container className="close-button-wrapper">
              <button
                className="close-button"
                onClick={() => {
                  setMegaMenuOpen(false);
                }}
              >
                <Shape variant="close-big" fill={colors.darkBlue} />
              </button>
            </Container>

            {navigationItems.map((item) => {
              const cards = parseJson<FormattedPageCards>(item.cards, true);

              return (
                <div
                  className={classNames(
                    "mega-menu__navigation-wrapper",
                    item !== activeNavigationItem &&
                    "mega-menu__navigation-wrapper--hidden",
                  )}
                  ref={(element: HTMLDivElement) =>
                    (menuWrappers.current[item.id] = element)
                  }
                  key={item.id}
                >
                  <Container>
                    <Row className="mega-menu__row-wrapper" key={item.id}>
                      <div className="mega-menu__navigation-tree-wrapper col-md-4">
                        <NavigationTree
                          root={item}
                          isActive={item === activeNavigationItem}
                          onOpenChildChange={(childId) =>
                            setIsMenuChildOpen(!!childId)
                          }
                        />
                      </div>

                      {cards && (
                        <div
                          className={classNames(
                            "mega-menu__card col-md-4",
                            isMenuChildOpen && "mega-menu__card--hidden",
                          )}
                        >
                          {cards?.map((card) => (
                            <Card
                              key={card.title}
                              variant="horizontal-thin"
                              className="no-hover"
                              title={card.title}
                              html={card.subtitle}
                              cardImg={
                                card.image?.[0] && {
                                  media: card.image[0],
                                  ar: "ar43",
                                }
                              }
                              actionButtons={[
                                {
                                  variant: "primary",
                                  label: card.button.title,
                                  url: card.button.link,
                                },
                              ]}
                              url={card.button.link}
                              targetBlank={card.button.blank}
                            />
                          ))}
                        </div>
                      )}
                    </Row>
                  </Container>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classNames("mega-menu-backdrop", transitionStatus)} />
      </>
    );
  },
);

MegaMenu.displayName = "MegaMenu";

const isActivePath = (path: string | null, item: NavigationItem) => {
  return (
    typeof path === "string" &&
    path !== "/" &&
    path.startsWith(item.url) &&
    !item.internalLink
  );
};

type NavigationItem = PageTree & {
  external?: boolean;
  hasChildren?: boolean;
  id: string;
  internalLink: boolean;
  lang?: string;
  parent?: string;
  show_in_menu?: boolean;
  title: string;
  url: string;
};

type HeaderNavigationProps = {
  content: PageProps["content"];
  entityId: string;
  header: PageProps["header"];
  nav: PageProps["nav"];
};

const HeaderNavigation: FC<HeaderNavigationProps> = ({
  entityId,
  nav,
  header,
  content,
}) => {
  const path = useSlugPath();
  const router = useRouter();
  const [activeNav, setActiveNav] = useState<PageTree | null>(null);
  const [activeAnchor, setActiveAnchor] = useState(null);
  const [fullPath, setFullPath] = useState(router.asPath);
  const [megaMenuOpen, setMegaMenuOpenState] = useState(false);
  const transitionRef = useRef<HTMLDivElement>(null);
  const {
    mobileNavOverlay,
    setMobileNavOverlay,
    setCustomerPortalOverlayOpen,
    isLandingpage,
  } = useNavContext();

  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.xl - 1}px)`,
  });

  const [localePortal, setLocalePortal] = useState<boolean>(false);
  const localePortalTrigger = useRef<HTMLDivElement>(null);

  const setMegaMenuOpen = useCallback((newOpen: boolean) => {
    setMegaMenuOpenState((oldOpen) => {
      if (oldOpen === newOpen) return newOpen;

      if (newOpen) {
        shameScroll({
          open: true,
        });
      } else {
        shameScroll({
          close: true,
        });
      }

      return newOpen;
    });
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setMegaMenuOpen(false);
      }
    };

    window.document.addEventListener("keydown", handleKeyDown);
    return () => {
      window.document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setMegaMenuOpen]);

  // disable subnavigation if specified in .env file
  const { country, language } = useLocale();
  const { publicRuntimeConfig } = getConfig();
  const megaMenuDisabled = (
    publicRuntimeConfig.DISABLE_MEGAMENU as string | undefined
  )
    ?.split(",")
    ?.includes(country);

  const { showCountryForwardingBanner } = useNavContext();
  const [scrollUp, setScrollUp] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [sticking, setSticking] = useState(false);
  const navigationRef = useRef<HTMLDivElement>(null);

  const setNavigationVariables = useCallback(() => {
    if (typeof document === "undefined") {
      return;
    }

    const metaMenu = document.getElementById("meta");
    const metaHeightWithOffset =
      (metaMenu &&
        ("checkVisibility" in metaMenu
          ? metaMenu.checkVisibility()
          : getComputedStyle(metaMenu).display !== "none") &&
        document.getElementById("meta").getBoundingClientRect().top +
        window.scrollY +
        document.getElementById("meta").getBoundingClientRect().height) ||
      0;

    const navigationClientHeight =
      ((!scrolled || scrollUp) &&
        navigationRef.current &&
        ("checkVisibility" in navigationRef.current
          ? navigationRef.current.checkVisibility()
          : getComputedStyle(navigationRef.current).display !== "none") &&
        navigationRef.current?.clientHeight) ||
      0;

    document.body.style.setProperty(
      "--meta-height",
      `${metaHeightWithOffset}px`,
    );

    document.body.style.setProperty(
      "--navigation-height",
      `${navigationClientHeight ?? 0}px`,
    );

    document.body.style.setProperty(
      "--header-height",
      `${metaHeightWithOffset + navigationClientHeight ?? 0}px`,
    );
  }, [scrollUp, scrolled]);

  const SCROLL_DIRECTION_CHANGE_THRESHOLD = 0;
  const lastScrollY = useRef(
    typeof window !== "undefined" ? window.scrollY : 0,
  );
  const scrollHandlerIsWorking = useRef(false);

  useEffect(() => {
    const scrollHandler = () => {
      if (typeof window === "undefined") {
        return;
      }

      const { scrollY } = window;
      if (scrollY === 0) {
        if (document.body.classList.contains("lightbox-opened")) {
          scrollHandlerIsWorking.current = false;
          return;
        }

        setScrollUp(false);
        setScrolled(false);
        setSticking(false);
      } else {
        const metaMenu = document.getElementById("meta");
        const navOffset =
          (metaMenu &&
            document.getElementById("meta").getBoundingClientRect().top +
            window.scrollY +
            document.getElementById("meta").getBoundingClientRect().height) ||
          0;
        const navHeightWithOffset =
          (navigationRef?.current?.offsetHeight ?? 0) + navOffset;

        const hasScrolledBeyondOriginalNavPosition =
          scrollY > navHeightWithOffset;

        setScrolled(hasScrolledBeyondOriginalNavPosition);

        const navIsSticking = window.scrollY > navOffset;
        setSticking(navIsSticking);

        const scrollYDifference = Math.abs(scrollY - lastScrollY.current);
        if (scrollYDifference < SCROLL_DIRECTION_CHANGE_THRESHOLD) {
          // not enough scroll to change direction
          scrollHandlerIsWorking.current = false;
          return;
        }

        if (scrollY !== lastScrollY.current) {
          // change scroll direction
          setScrollUp(scrollY < lastScrollY.current);
        }
      }
      lastScrollY.current = scrollY;
      scrollHandlerIsWorking.current = false;
    };

    const onScroll = () => {
      if (!scrollHandlerIsWorking.current) {
        scrollHandlerIsWorking.current = true;
        window.requestAnimationFrame(scrollHandler);
      }
    };

    const resizeHandler = () => {
      // if width < 992px, close mega menu
      if (window.innerWidth < 992) {
        setMegaMenuOpen(false);
      }

      setNavigationVariables();
    };

    window.addEventListener("scroll", onScroll);
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", resizeHandler);
    };
  }, [setNavigationVariables, setMegaMenuOpen]);

  useEffect(() => {
    setNavigationVariables();
  }, [scrolled, scrollUp, showCountryForwardingBanner, setNavigationVariables]);

  Router.events.on("routeChangeStart", () => {
    setMobileNavOverlay(false);
    setMegaMenuOpen(false);
  });

  // filtering out special nav items like meta menu or footer
  const navigationItems = getMenuPageTreeChildren(nav)?.filter(
    (navItem) =>
      !Object.values(nav_settings).some((pageId) => navItem.id === pageId),
  );

  const handleAnchorClick = (e, anchorId) => {
    e.preventDefault();

    const element = document.getElementById(anchorId);
    if (element) {
      const currentScrollY = window.scrollY;
      const elementPosition =
        element.getBoundingClientRect().top + currentScrollY;
      const bodyStyles = getComputedStyle(document.body);
      const navHeight = parseInt(
        bodyStyles.getPropertyValue("--navigation-height"),
        10,
      );

      const offset = navigationRef?.current?.offsetHeight || navHeight;

      const isAboveCurrentView = elementPosition < currentScrollY;

      const yOffset = isAboveCurrentView ? -offset : 0;
      const yPosition = elementPosition + yOffset;
      window.scrollTo({ top: yPosition, behavior: "smooth" });

      // add anchor id to url
      if (window.history.pushState) {
        window.history.pushState(null, "", `#${anchorId}`);
      } else {
        // fallback for older browsers
        window.location.hash = `#${anchorId}`;
      }
    }
  };

  useEffect(() => {
    const anchor = fullPath.includes("#") ? fullPath.split("#")[1] : null;
    setActiveAnchor(anchor);
  }, [fullPath]);

  const isActiveAnchor = (currentUrl: string | null, href: string) => {
    if (typeof currentUrl !== "string" || currentUrl === "/") {
      return false;
    }
    return activeAnchor === href;
  };

  const getAnchorNavigationItems = (items: PageProps["content"][]) => {
    return items?.filter(
      (item) => item.anchor_navigation && item.anchor_navigation_title,
    );
  };

  const anchorNavigationItems = getAnchorNavigationItems(content?.content);

  // get active anchor-nav item
  const activeAnchorItem = navigationRef.current?.querySelectorAll(
    ".nav-item .nav-link",
  )?.[
    anchorNavigationItems?.findIndex((item) => {
      const itemAnchor = getSpeakingId({ title: item.anchor_navigation_title });
      return activeAnchor
        ? getSpeakingId({ title: activeAnchor }) === itemAnchor
        : null;
    })
  ];

  // get active navigation item
  const activeNavigationItem = navigationRef.current?.querySelectorAll(
    ".nav-item .nav-link",
  )?.[
    navigationItems?.findIndex(
      (item) =>
        (!megaMenuOpen && isActivePath(path, item)) ||
        (megaMenuOpen && activeNav.url === item.url),
    )
  ];

  // remember last indicator position and width
  const indicatorStyle = useRef<{ left: number; width: number } | undefined>();
  if (activeNavigationItem || activeAnchorItem) {
    const activeItem = activeNavigationItem
      ? activeNavigationItem
      : activeAnchorItem
        ? activeAnchorItem
        : null;

    const offsetWidth = isMobile ? 2 * 12 : 2 * 18;
    const offsetLeft = isMobile ? 12 : 18;

    indicatorStyle.current = {
      width: activeItem.clientWidth - offsetWidth,
      left: activeItem.parentElement.offsetLeft + offsetLeft,
    };
  }

  // animate position and with of indicator after first activated
  const indicatorElement = navigationRef.current?.querySelector(".indicator");
  if (typeof window !== "undefined") {
    window.requestAnimationFrame(() => {
      if (activeNavigationItem) {
        indicatorElement?.classList[activeNavigationItem ? "add" : "remove"](
          "animated",
        );
      } else if (activeAnchorItem) {
        indicatorElement?.classList[activeAnchorItem ? "add" : "remove"](
          "animated",
        );
      }
    });
  }

  if (isEmpty(nav)) return null;
  if (!("children" in nav) && !anchorNavigationItems) return null;

  return (
    <header
      className={classNames(
        "header",
        sticking && "header-sticking",
        scrolled && "header-scrolled",
        scrollUp && "header-narrow",
      )}
    >
      <div
        className={classNames(
          "navigation",
          megaMenuOpen && "navigation--with-open-mega-menu",
        )}
        ref={navigationRef}
      >
        <Container>
          {isLandingpage ? (
            <Row className="d-sm-none p-2 border-bottom">
              <Col
                md={12}
                className="col-md-12 justify-content-center align-items-center d-flex"
              >
                <Link
                  href={nav?.url}
                  href={nav?.url}
                  prefetch={false}
                  className={
                    isLandingpage
                      ? "font-weight-bolder text-primary p-0 nav-link"
                      : "p-0 nav-link"
                  }
                >
                  {nav.navigation_title ?? nav.title}
                </Link>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col
              md={12}
              className="col-md-12 justify-content-between align-items-center d-flex"
            >
              {isLandingpage && (
                <Link
                  href={
                    header?.base?.home_link ? header?.base?.home_link : nav?.url
                  }
                  prefetch={false}
                  className="p-0 nav-link mobile-las-logo"
                >
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <Logo className="logo" />
                </Link>
              )}
              <Link
                href={nav?.url}
                href={nav?.url}
                prefetch={false}
                className={
                  isLandingpage
                    ? "font-weight-bolder text-primary p-0 nav-link d-none d-sm-block"
                    : "p-0 nav-link"
                }
              >
                {isLandingpage ? (
                  <>{nav.navigation_title ?? nav.title}</>
                ) : (
                  <>
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <Logo className="logo" />
                  </>
                )}
              </Link>
              <Row
                className="justify-content-center align-items-center"
                as="nav"
              >
                <Nav as="ul">
                  {!anchorNavigationItems?.length
                    ? navigationItems.map((item) =>
                      item.show_in_menu && item.url ? (
                        <Nav.Item
                          key={item.id}
                          className={classNames(
                            isActivePath(path, item) && "active",
                          )}
                          as="li"
                        >
                          {item.external ? (
                            <a
                              href={item.url}
                              target="_blank"
                              rel="noreferrer"
                              className="nav-link"
                            >
                              {item.navigation_title ?? item.title}
                            </a>
                          ) : (
                            <Link
                              href={item.url !== null ? item.url : ""}
                              passHref={true}
                              prefetch={false}
                              legacyBehavior
                            >
                              <Nav.Link
                                onClick={(e: MouseEvent) => {
                                  if (megaMenuDisabled) {
                                    return;
                                  }

                                  setActiveNav(item);
                                  setMegaMenuOpen(true);
                                  e.preventDefault();
                                }}
                                active={
                                  (!megaMenuOpen &&
                                    isActivePath(path, item)) ||
                                  (megaMenuOpen && activeNav.url === item.url)
                                }
                              >
                                {item.navigation_title ?? item.title}
                              </Nav.Link>
                            </Link>
                          )}
                        </Nav.Item>
                      ) : null,
                    )
                    : anchorNavigationItems.map((anchor, i) => (
                      <Nav.Item
                        key={anchor.anchor_navigation_title + i}
                        as="li"
                        className={classNames("anchor")}
                        onClick={(e: MouseEvent) => {
                          handleAnchorClick(
                            e,
                            getSpeakingId({
                              title: anchor.anchor_navigation_title,
                            }),
                          );
                        }}
                      >
                        <Nav.Link
                          href={`#${getSpeakingId({
                            title: anchor.anchor_navigation_title,
                          })}`}
                          className={
                            isActiveAnchor(
                              fullPath,
                              getSpeakingId({
                                title: anchor.anchor_navigation_title,
                              }),
                            ) && "active"
                          }
                          onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            setActiveAnchor(anchor.anchor_navigation_title);
                            setFullPath(router.asPath);
                          }}
                        >
                          {anchor.anchor_navigation_title}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  <span
                    className={classNames(
                      "indicator",
                      activeNavigationItem && "active",
                      activeAnchorItem && "active",
                    )}
                    style={indicatorStyle.current}
                  />
                </Nav>
              </Row>
              <div className="search-nav">
                <>
                  <div
                    ref={localePortalTrigger}
                    className="open-locale-switcher d-lg-none flex-center"
                    onClick={() => {
                      isLandingpage
                        ? setLocalePortal(!localePortal)
                        : setMobileNavOverlay(false);
                    }}
                  >
                    <Shape variant="globe" fill={colors.darkBlue} />
                  </div>
                  <LanguagePortal
                    align="end"
                    currentCountry={header?.base?.currentCountry}
                    entityId={entityId}
                    offset={8}
                    open={localePortal}
                    setOpen={setLocalePortal}
                    trigger={localePortalTrigger}
                    pageSlug={nav?.url}
                  />
                </>
                {isLandingpage ? (
                  <Link
                    href={
                      header?.base?.home_link
                        ? header?.base?.home_link
                        : nav?.url
                    }
                    prefetch={false}
                    target="_blank"
                    className="p-0 nav-link desktop-las-logo"
                  >
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <Logo className="logo" />
                  </Link>
                ) : (
                  <Search header={header} />
                )}
                {publicRuntimeConfig.APP_ENV === "live" ? null : (
                  <div
                    className="open-mykrohne d-lg-none"
                    onClick={() => setCustomerPortalOverlayOpen(true)}
                  >
                    <Shape variant="user" fill={colors.darkBlue} />
                  </div>
                )}
                <div
                  className="open-mm"
                  onClick={() => setMobileNavOverlay(!mobileNavOverlay)}
                >
                  {mobileNavOverlay ? (
                    <Shape variant="close-big" fill={colors.darkBlue} />
                  ) : (
                    <Shape
                      variant="list-thin"
                      fill={colors.darkBlue}
                      size={18}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Transition
        in={megaMenuOpen}
        nodeRef={transitionRef}
        onExited={() => setActiveNav(null)}
        mountOnEnter
        unmountOnExit
        appear
        timeout={250}
      >
        {(status) => (
          <MegaMenu
            ref={transitionRef}
            navigationItems={navigationItems}
            activeNavigationItem={activeNav}
            setMegaMenuOpen={setMegaMenuOpen}
            transitionStatus={status}
          />
        )}
      </Transition>
    </header>
  );
};

const generateTree = (
  root: NavigationItem,
  tree: NavigationItem[] = [],
  parent = null,
) => {
  const { children, ...item } = root;

  tree.push({
    parent: parent,
    hasChildren: children
      ? Object.values(children)?.filter((x) => x.show_in_menu).length > 0
      : false,
    ...item,
  });

  if (children) {
    Object.values(children)?.map((child, index) => {
      generateTree(child, tree, item.id);
    });
  }

  return tree;
};

export const MobileNavigation = ({
  root,
  header,
  footer,
  related_links,
}: {
  footer: MetaNav;
  header: PageProps["header"];
  related_links: any;
  root: NavigationItem;
}) => {
  const tree = generateTree(root);
  const path = useSlugPath();

  function findOrFallback() {
    // check, if on detail page (which is not rendered in menu) and use parent NavigationItem instead
    let foundElement = tree.find((x) => x.url === path);

    if (!foundElement) {
      const newPath = path.substring(0, path.lastIndexOf("/"));
      foundElement = tree.find((x) => x.url === newPath);
    }
    return foundElement;
  }

  const findParentFromSlug = () => {
    let parent = path ? findOrFallback() : root;

    if (!parent?.hasChildren && parent.parent) {
      parent = path ? tree.find((x) => x.id === parent.parent) : root;
    }

    return parent;
  };

  const [parent, setParent] = useState<NavigationItem>(findParentFromSlug());

  const [direction, setDirection] = useState<"left" | "right">("left");

  const [isTransitioning, setIsTransitioning] = useState(false);

  const updateNavigation = (
    parent: NavigationItem,
    direction: "left" | "right",
  ) => {
    setDirection(direction);

    setTimeout(() => {
      setParent(parent);
    }, 100);
  };

  const panelRef = useRef(null);

  if (isEmpty(root)) return null;
  if (!has(root, "children")) return null;

  const MenuPanel = () => {
    const children = tree.filter((x) => x.parent === parent.id);

    return (
      <>
        <li className="bold header">
          <div className="d-flex">
            {parent?.id !== root.id ? (
              <a
                className="d-flex"
                href="#"
                onClick={(event) => {
                  event.stopPropagation();
                  updateNavigation(
                    parent ? tree.find((x) => x.id === parent.parent) : root,
                    "right",
                  );
                }}
              >
                <Shape variant={"caret-left"} fill={colors.gray40} size={16} />
              </a>
            ) : null}

            <Link
              href={parent.url}
              passHref={true}
              prefetch={false}
              legacyBehavior
            >
              <Nav.Link
                className={classNames(
                  "flex-fill d-flex align-items-center justify-content-center",
                  {
                    active: parent.url === path,
                  },
                )}
              >
                {parent.navigation_title ?? parent.title}
              </Nav.Link>
            </Link>
          </div>
        </li>

        {children.map((item: NavigationItem) => {
          const itemActive =
            path === item.url
              ? path === item.url
              : // set parent NavItem active when on detailPage (without own NavItem in menu)
              !item.hasChildren
                ? path.substring(0, path.lastIndexOf("/")) === item.url
                : null;

          return item.show_in_menu && item.url ? (
            <li key={item.id}>
              <div className="d-flex">
                {item.external ? (
                  <a
                    href={item.url}
                    target="_blank"
                    className="flex-fill"
                    rel="noreferrer"
                  >
                    <div style={{ display: "flex" }}>
                      <Shape variant={"external-link"} fill={colors.darkBlue} />
                      <div className="ml-1">
                        {item.navigation_title ?? item.title}
                      </div>
                    </div>
                  </a>
                ) : (
                  <Link
                    href={item.url}
                    passHref={true}
                    prefetch={false}
                    legacyBehavior
                  >
                    <Nav.Link
                      className={classNames("flex-fill", {
                        active: itemActive,
                      })}
                    >
                      {item.navigation_title ?? item.title}
                    </Nav.Link>
                  </Link>
                )}
                {item.hasChildren && !isTransitioning ? (
                  <a
                    className="d-flex"
                    href="#"
                    onClick={() => updateNavigation(item, "left")}
                  >
                    <Shape
                      variant={"caret-right"}
                      fill={colors.gray40}
                      size={16}
                    />
                  </a>
                ) : null}
              </div>
            </li>
          ) : null;
        })}
      </>
    );
  };
  return (
    <div className="mobile-navigation-wrapper mx-auto">
      <div className="mobile-navigation mb-top">
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={parent.id}
            nodeRef={panelRef}
            addEndListener={(done: any) => {
              panelRef.current.addEventListener("transitionend", done, false);
            }}
            onExit={() => setIsTransitioning(true)}
            onEntered={() => setIsTransitioning(false)}
            classNames={`mobile-menu-slide-${direction}`}
            timeout={150}
          >
            <ul
              ref={panelRef}
              className={`container list-unstyled`}
              data-path={parent.url}
            >
              {<MenuPanel />}
            </ul>
          </CSSTransition>
        </SwitchTransition>
      </div>
      <div className="mobile-navigation mb-bottom">
        <ul className="list-unstyled mobile-metanav container">
          {header.meta &&
            header.meta.length > 0 &&
            header.meta.map((item) => {
              return item.show_in_menu && item.url ? (
                <li key={item.id}>
                  <Link
                    href={item.url}
                    passHref={true}
                    prefetch={false}
                    legacyBehavior
                    {...(item.external ? { target: "_blank" } : {})}
                  >
                    <Nav.Link className="nav-link">
                      {item.navigation_title ?? item.title}
                    </Nav.Link>
                  </Link>
                </li>
              ) : null;
            })}
        </ul>
      </div>
      <div className="web-app-mobile-wrapper">
        <WebApps
          apps={header?.base?.web_apps}
          webAppsLabel={header?.base?.web_apps_label?.label}
          mobile={true}
        />

        <div className="footer-mobile-nav-container">
          <nav className="footer-mobile-nav justify-content-start">
            {footer &&
              footer.map((item) =>
                item.url ? (
                  <Nav.Item key={item.id}>
                    <Link
                      href={item.url}
                      passHref={true}
                      prefetch={false}
                      legacyBehavior
                      rel={`${item.url !== "/other-krohne-websites" ? "nofollow" : ""
                        }`}
                    >
                      <Nav.Link>{item.navigation_title ?? item.title}</Nav.Link>
                    </Link>
                  </Nav.Item>
                ) : null,
              )}
          </nav>
          <div className="footer__related-links">
            {related_links.map((link) => {
              if (!link.key_visual?.[0]) return null;
              const url = new URL(link.key_visual?.[0].link_absolute_path);
              const match = /\/([a-z_]+).([a-z]+)$/gm.exec(url.pathname);
              if (match) {
                const [, icon] = match;
                return (
                  <a
                    href={link?.link_absolute_path}
                    target="_blank"
                    rel="nofollow noreferrer"
                    key={url.pathname}
                  >
                    <Shape variant={icon as Icon} size={25} />
                  </a>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderNavigation;
