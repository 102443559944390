import { breakpoints, colors } from "theme/theme";
import { Button } from "components/button";
import { Cloudinary } from "components/cloudinary";
import { Container } from "components/container";
import { entitiesConfig } from "config/entity";
import { EntityGrid } from "components/contentset/entity-grid";
import { EntityTable } from "components/contentset/entity-table";
import {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FilterModal } from "components/contentset/filter";
import { FilterPreview } from "components/contentset/filter-preview";
import { FilterSelectedOptionTags } from "./filter-selected-option-tag";
import { isEmpty } from "lodash-es";
import { isLandingpage as checkLandingpage } from "helpers/routing/utils";
import { LoadingSpinner } from "components/loading-spinner";
import { makeParagraph } from "helpers/text-processing";
import { DropdownFilter } from "components/contentset/dropdown-filter";
import { shameScroll } from "helpers/shame-scroll";
import { Shape } from "components/shape";
import { useContentsetContext } from "contexts/contentset-context";
import { useLabels } from "helpers/hooks";
import { useMediaQuery } from "react-responsive";
import { useRouter } from "next/router";
import { useSearchParams } from "next/navigation";
import { useSlugPath } from "helpers/routing";
import classNames from "classnames";
import FilterButtonGroup from "./filter-button-group";
import ViewSwitcher from "components/contentset/view-switcher";
import { SearchFilter } from "./search-filter";
import { ZipFilter } from "./zip-filter";

interface InteriorProps {
  headline: string;
}

export const ContentSetInterior: FC<InteriorProps> = ({ headline }) => {
  const {
    searchValue,
    contentset,
    selectedOptions,
    filters,
    setModalState,
    setModalTarget,
    view,
    base,
    searchInput,
    dataInfo,
    isLoadingContentsetPage,
    clearFilters,
  } = useContentsetContext();
  if (contentset.dlc_api) contentset.entity_type = "video";
  const entityConfig =
    entitiesConfig[contentset.entity_type] ?? entitiesConfig._default;
  const detailEntityType = entityConfig.detail_page_entity_type
    ? entityConfig.detail_page_entity_type
    : "kp";
  const [counterBtn, filterBtn, resetBtn, toggleFilterOptionsBtn] = useLabels(
    [entityConfig.counter_definition, "Showing"],
    ["ui-618", "Filter"],
    ["ui-1", "Reset all filters"],
    ["ui-871", "Show filter options"],
  );
  const getKrohnePick = () =>
    Object.values(searchValue).find((el) => el?.isKrohnePick) ?? null;

  const [krohnePick, setKrohnePick] = useState(getKrohnePick());

  // Helper functions to determine wether to show content
  // Check if filters are selected here
  const entitiesAreFiltered = useCallback(
    () =>
      !isEmpty(selectedOptions) ||
      !isEmpty(searchValue) ||
      !isEmpty(searchInput),
    [selectedOptions, searchValue, searchInput],
  );

  const shouldShowEntities = () =>
    !krohnePick && (!contentset.show_preview_filter || entitiesAreFiltered());
  const shouldShowPreview = () =>
    contentset.show_preview_filter && !entitiesAreFiltered();

  // Booleans determining wether to show some content
  const [showEntities, setShowEntities] = useState(shouldShowEntities());
  const [showPreview, setShowPreview] = useState(shouldShowPreview());

  const stickyFilterHeaderRef = useRef<HTMLDivElement>(null);
  const [isSticking, setIsSticking] = useState(false);
  const [stickyFilterHeaderOpen, setStickyFilterHeaderOpen] = useState(false);
  const [, setStickyFilterHeaderHeight] = useState(56);

  useEffect(() => {
    if (!stickyFilterHeaderRef.current) return;

    setStickyFilterHeaderHeight(stickyFilterHeaderRef.current.offsetHeight);
  }, [isSticking, stickyFilterHeaderOpen, selectedOptions, searchValue]);

  const [isFiltered, setIsFiltered] = useState(false);

  const router = useRouter();
  const searchParams = useSearchParams();

  useEffect(() => {
    const baseUrl = window.location.origin + window.location.pathname;

    router.beforePopState(() => {
      if (contentset.layout === "dlc" && isFiltered) {
        void router.push(baseUrl, baseUrl, { shallow: true });
        return false;
      }

      return true;
    });

    return () => {
      router.beforePopState(() => true);
    };
  }, [contentset, isFiltered, router]);

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      if (contentset.layout === "dlc" && searchParams.size === 0) {
        clearFilters();
      }
    };

    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [contentset, router, searchParams, clearFilters]);

  useEffect(() => {
    setKrohnePick(getKrohnePick());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  // Add additional className to filter elements, which are not in first line
  const filterButtonWrapperRef = useRef<HTMLDivElement>(null);

  const filterWrapperRef = useRef<HTMLDivElement>(null);

  const scrollHandler = () => {
    const filterWrapper = filterWrapperRef.current;
    if (!filterWrapper) {
      return;
    }

    const clientTop = filterWrapper.getBoundingClientRect().bottom;
    const stickyHeight =
      parseInt(
        getComputedStyle(filterWrapper).getPropertyValue("--navigation-height"),
        10,
      ) +
      (parseInt(
        getComputedStyle(filterWrapper).getPropertyValue("--tab-height"),
        10,
      ) || 0);
    const newIsSticking = clientTop <= stickyHeight;

    const isVisible = !!filterWrapper.offsetParent;
    if (!isVisible) return;

    setIsSticking(newIsSticking);
  };

  useEffect(() => {
    setShowPreview(shouldShowPreview());
    setShowEntities(shouldShowEntities());
    setIsFiltered(
      (!isEmpty(searchValue) &&
        filters.some(
          (filter) => filter.filter_type === "single-value-dropdown",
        )) ||
        (!isEmpty(searchInput) &&
          filters.some(
            (filter) => filter.filter_type === "single-value-dropdown",
          )) ||
        (!isEmpty(selectedOptions) &&
          filters.some(
            (filter) =>
              filter.filter_type === "single-value-radio-button" ||
              filter.filter_type === "multiple-value-check-box-list",
          )),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [krohnePick, searchValue, searchInput, selectedOptions]);

  useEffect(() => {
    if (typeof window === "undefined") return;

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.md - 1}px)`,
  });

  const openFilterModal = () => {
    if (filters.length > 0) {
      setModalState(true);
      setModalTarget(filters[0].id);
      shameScroll({ open: true });
    }
  };

  const searchFilterExists = useMemo(
    () => filters.some((filter) => filter.filter_type === "search"),
    [filters],
  );

  const isLandingpage = checkLandingpage(useSlugPath());
  const NoContainerOnDefaultView = isLandingpage ? Container : Fragment;

  return (
    <>
      {contentset.layout !== "finder" && (
        <>
          <>
            {Boolean(filters.length) && isSticking && (
              <div
                className={classNames(
                  "filter-wrapper",
                  !filters.length && "no-filters",
                  !isFiltered && "not-filtered",
                  "is-sticking",
                  stickyFilterHeaderOpen && "is-open",
                )}
              >
                <div
                  className="filter-wrapper__content"
                  ref={stickyFilterHeaderRef}
                >
                  {!stickyFilterHeaderOpen ? (
                    <>
                      <div className="filter-sticky-summary">
                        <div className="filter-sticky-summary__content">
                          <div
                            className="filter-title"
                            onClick={() => openFilterModal()}
                          >
                            <Shape
                              className="beforeIcon"
                              fill={colors.darkBlue}
                              variant="filter"
                            />
                            <p className="h6">{filterBtn.label}</p>
                          </div>

                          {isFiltered && (
                            <div className="filter-values-wrapper">
                              <FilterSelectedOptionTags showSearchTags />

                              <Button
                                className="reset-button"
                                variant="small"
                                icon="close"
                                label={resetBtn.label}
                                onClick={clearFilters}
                              />
                            </div>
                          )}
                        </div>

                        <Button
                          className="filter-sticky-summary__show-more-button"
                          label={toggleFilterOptionsBtn.label}
                          variant="primary"
                          icon={
                            isMobile ? "caret-right-small" : "caret-down-small"
                          }
                          onClick={() =>
                            isMobile
                              ? openFilterModal()
                              : setStickyFilterHeaderOpen(true)
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="filter-sticky-title-wrapper">
                        <div
                          className="filter-title"
                          onClick={() => openFilterModal()}
                        >
                          <Shape
                            className="beforeIcon"
                            fill={colors.darkBlue}
                            variant="filter"
                          />
                          <p className="h6">{filterBtn.label}</p>
                        </div>

                        <Button
                          className="filter-sticky-summary__show-more-button"
                          label={toggleFilterOptionsBtn.short_title}
                          variant="primary"
                          icon="caret-up-small"
                          onClick={() => setStickyFilterHeaderOpen(false)}
                        />
                      </div>

                      <div
                        className="filter-button-wrapper"
                        ref={filterButtonWrapperRef}
                      >
                          {filters.map((filter) =>
                          filter.filter_type === "search" ? (
                            <SearchFilter key={filter.id} filter={filter} />
                          ) : filter.filter_type === "single-value-dropdown" ? (
                            <DropdownFilter
                              key={filter.id}
                              filter={filter}
                              inModal={contentset.entity_type === "ws"}
                              hideSearchIcon={searchFilterExists}
                            />
                              ) : filter.filter_type === "zip-search" ? (
                                <ZipFilter key={filter.id} filter={filter} />
                          ) : (
                            <FilterButtonGroup
                              key={filter.id}
                              filters={[filter]}
                              selectedOptions={selectedOptions}
                            />
                          ),
                        )}
                      </div>
                      {isFiltered && (
                        <div className="filter-values-wrapper">
                          <FilterSelectedOptionTags />

                          <Button
                            className="reset-button"
                            variant="small"
                            icon="close"
                            label={resetBtn.label}
                            onClick={clearFilters}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
            {!showPreview && (
              <NoContainerOnDefaultView>
                <div
                  className={classNames(
                    "filter-wrapper",
                    !filters.length && "no-filters",
                    !isFiltered && "not-filtered",
                  )}
                  ref={filterWrapperRef}
                >
                  <div
                    className={classNames(
                      "filter-options-wrapper",
                      headline && !filters.length && "justify-content-between",
                    )}
                  >
                    {!filters.length && (
                      <>
                        {contentset.default_view === "fullscreen_button" &&
                        headline ? (
                          <p className="headline h3">{headline}</p>
                        ) : (
                          <div></div>
                        )}
                      </>
                    )}

                    {!!filters.length && (
                      <div
                        className="filter-title"
                        onClick={() => openFilterModal()}
                      >
                        <Shape
                          className="beforeIcon"
                          fill={colors.darkBlue}
                          variant="filter"
                        />
                        <p className="h6">{filterBtn.label}</p>
                      </div>
                    )}

                    <div className="filter-options">
                      {contentset.default_view_counter &&
                        (isLoadingContentsetPage ? (
                          <LoadingSpinner size={21} color={colors.gray60} />
                        ) : (
                          <div className="amount">
                            {`${dataInfo.itemsTotal} ${counterBtn.cs_counter_suffix}`}
                          </div>
                        ))}
                      {contentset.default_view_changer && <ViewSwitcher />}
                    </div>
                  </div>
                  <div
                    className="filter-button-wrapper"
                    ref={filterButtonWrapperRef}
                  >
                    {filters.map((filter) =>
                      filter.filter_type === "search" ? (
                        <SearchFilter key={filter.id} filter={filter} />
                      ) : filter.filter_type === "single-value-dropdown" ? (
                        <DropdownFilter
                          key={filter.id}
                          filter={filter}
                          inModal={contentset.entity_type === "ws"}
                          hideSearchIcon={searchFilterExists}
                        />
                        ) : filter.filter_type === "zip-search" ? (
                          <ZipFilter key={filter.id} filter={filter} />
                      ) : (
                        <FilterButtonGroup
                          key={filter.id}
                          filters={[filter]}
                          selectedOptions={selectedOptions}
                        />
                      ),
                    )}
                  </div>
                  {isFiltered && (
                    <div className="filter-values-wrapper">
                      <FilterSelectedOptionTags />

                      <Button
                        className="reset-button"
                        variant="small"
                        icon="close"
                        label={resetBtn.label}
                        onClick={clearFilters}
                      />
                    </div>
                  )}
                </div>
              </NoContainerOnDefaultView>
            )}
            {filters.length > 0 && (
              <FilterModal
                filters={filters}
                base={base}
                detailEntityType={detailEntityType}
              />
            )}
            {!showPreview && (
              <NoContainerOnDefaultView>
                <hr
                  className={classNames(
                    "filter-divider",
                    view.active === "fullscreen_button" && "fullsize",
                  )}
                />
              </NoContainerOnDefaultView>
            )}
          </>
          {krohnePick && (
            <div className="krohne-pick-banner">
              <Cloudinary
                className="krohne-pick-background"
                media={base.search.pick.im}
              />
              <div>
                <p className="h5">{base.search.tb?.title}</p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: makeParagraph(base.search.pick.tb?.text_body),
                  }}
                ></div>
              </div>
              <Button
                label={base.search.pick.tb?.subtitle}
                variant="secondary"
                onClick={() =>
                  window.open(`https://pick.krohne.com/${krohnePick.value}`)
                }
              />
            </div>
          )}
          {showPreview ? (
            <FilterPreview />
          ) : (
            showEntities &&
            (view.active === "fullscreen_button" ? (
              <Container fluid>
                <EntityGrid />
              </Container>
            ) : (contentset.entity_type === "ws" && isMobile) ||
              view.active !== "list_thin" ? (
              <NoContainerOnDefaultView>
                <EntityGrid isMobile={isMobile} />
              </NoContainerOnDefaultView>
            ) : (
              <NoContainerOnDefaultView>
                <EntityTable />
              </NoContainerOnDefaultView>
            ))
          )}
        </>
      )}
    </>
  );
};
