import "theme/app.scss";
import { AppProps } from "next/app";
import { breakpoints } from "theme/theme";
import { cloneDeep, isEmpty } from "lodash-es";
import { CSSTransition } from "react-transition-group";
import { FormContextProvider } from "contexts/form-context";
import { FormModal } from "components/forms/form-modal";
import { getAppInfo, parseJson } from "helpers/utils";
import { getCountryAndLanguage } from "helpers/locale/utils";
import { isLandingpage as checkLandingpage } from "helpers/routing/utils";
import { LabelContextProvider } from "contexts/label-context";
import { LoadingSpinner } from "components/loading-spinner";
import { LocaleContextProvider } from "contexts/locale-context";
import { NavContextProvider } from "contexts/nav-context";
import { PreloadProvider } from "components/preload";
import { RoutingContextProvider } from "contexts/routing-context";
import { StickyConversion, StickyItem } from "components/sticky-conversion";
import { useEffect, useState } from "react";
import { useLabels, useNormalScrollRoutes } from "helpers/hooks";
import { useMediaQuery } from "react-responsive";
import { UserDataContextProvider } from "contexts/user-data-context";
import { UserProvider } from "@auth0/nextjs-auth0/client";
import Body from "components/_layout/page/body";
import Breadcrumb from "components/_layout/page/breadcrumb";
import ChatModal from "components/chatmodal";
import classNames from "classnames";
import CountryForwarding from "components/_layout/page/country-forwarding";
import dynamic from "next/dynamic";
import Footer from "components/_layout/page/footer";
import getConfig from "next/config";
import localFont from "next/font/local";
import MetaNavigation from "components/_layout/page/meta-navigation";
import NProgress from "nprogress";
import PageHead from "components/_layout/page/page-head";
import Router, { useRouter } from "next/router";
import Tracker from "components/_layout/page/tracker";

import HeaderNavigation, {
  MobileNavigation,
} from "components/_layout/page/navigation";

import {
  FormContextProps,
  LabelContextProps,
  PageProps,
  PageTree,
  PublicRuntimeConfigType,
} from "constants/types";

Router.events.on("routeChangeStart", (url, options) => {
  if (options?.shallow) return;
  NProgress.start();
});
Router.events.on("routeChangeComplete", () => {
  NProgress.done();
  setTimeout(() => {
    if (document.documentElement.dataset.noLoading) {
      delete document.documentElement.dataset.noLoading;
    }
  }, 1000);

  if (typeof window?.et_eC_Wrapper === "function") {
    const title = document.getElementsByTagName("title")[0]?.text || "";
    const config = { et_et: "RhxMdV", et_pagename: title };
    void window.et_eC_Wrapper(config);
  }
});
Router.events.on("routeChangeError", () => NProgress.done());

type KrohneAppProps = Omit<AppProps<PageProps>, "pageProps"> & {
  pageProps: PageProps;
};

const findCurrentLandingpage = (nav: PageTree, path: string) => {
  const landingpages = nav
    ? Object.values(nav.children).find((item) => item?.url === "/landingpages")
    : null;
  const landingpage =
    nav && landingpages?.children
      ? Object.values(landingpages?.children).find((item: PageTree) =>
        path.startsWith(item?.url),
      )
      : null;
  return landingpage;
};

const dinWebProFont = localFont({
  variable: "--din-web-pro-font",
  src: [
    {
      path: "../theme/fonts/DINWebPro-Medium.woff2",
      weight: "700",
      style: "normal",
    },
    {
      path: "../theme/fonts/DINWebPro-Light.woff2",
      weight: "400",
      style: "normal",
    },
    {
      path: "../theme/fonts/DINWebPro-Extralight.woff2",
      weight: "300",
      style: "normal",
    },
  ],
  fallback: ["Helvetica Neue", "Arial", "sans-serif"],
});

const ServiceForm = dynamic(() => import("components/service-form"), {
  ssr: false,
});

const CustomerPortalOverlay = dynamic(
  () => import("components/customer-portal/Overlay"),
  {
    ssr: false,
  },
);

const getUppercaseDomainAndTLD = (urlString) => {
  try {
    // Erstellen Sie ein URL-Objekt aus dem gegebenen String
    const url = new URL(urlString);
    // Extrahieren Sie den Hostnamen (z.B. "www.krohne.com")
    const hostname = url.hostname;
    // Teilen Sie den Hostnamen in seine Bestandteile auf
    const parts = hostname.split(".");
    // Überprüfen Sie, ob genügend Teile vorhanden sind
    if (parts.length >= 2) {
      // Die TLD ist der letzte Teil (z.B. "com")
      const tld = parts.pop();
      // Die Domain ist der vorletzte Teil (z.B. "krohne")
      const domain = parts.pop();
      // Wandeln Sie die Domain in Großbuchstaben um
      const upperDomain = domain.toUpperCase();
      // Kombinieren Sie die umgewandelte Domain mit der TLD
      return `${upperDomain}.${tld}`;
    } else {
      // Falls der Hostname nicht das erwartete Format hat
      return hostname.toUpperCase();
    }
  } catch (error) {
    return null;
  }
};

const App = ({ Component, pageProps }: KrohneAppProps) => {
  const { publicRuntimeConfig }: PublicRuntimeConfigType = getConfig();
  useNormalScrollRoutes();
  const { locale, slugPath } = pageProps;
  const [, language] = getCountryAndLanguage(locale);
  const { isFallback } = useRouter();
  const isLandingpage = checkLandingpage(pageProps?.header?.paths?.[language]);

  const nav =
    isLandingpage && pageProps?.nav
      ? findCurrentLandingpage(pageProps?.nav, slugPath)
      : pageProps?.nav;

  if (isLandingpage) {
    delete pageProps.header?.breadcrumb[0];
    delete pageProps.header?.breadcrumb[1];
    pageProps.header.meta = [
      {
        external: true,
        id: "page-1",
        lang: language,
        show_in_menu: true,
        title: pageProps?.header?.base?.home_link
          ? getUppercaseDomainAndTLD(pageProps?.header?.base?.home_link)
          : "KROHNE.com",
        icon: "home",
        url: pageProps?.header?.base?.home_link ?? "https://www.krohne.com",
      },
    ];
  }

  const [formModal, toggleFormModal] = useState<false | string>(false);
  const [localeOverlay, setLocaleOverlay] = useState(false);
  const [mobileNavOverlay, setMobileNavOverlay] = useState(false);
  const [customerPortalOverlayOpen, setCustomerPortalOverlayOpen] =
    useState(false);
  const [showCountryForwardingBanner, setShowCountryForwardingBanner] =
    useState(false);
  const [entity, setEntity] = useState(null);
  const [formCt, setFormCt] = useState(null);
  const [formCtData, setFormCtData] = useState(null);
  const [showChatModal, setShowChatModal] = useState(false);
  const [serviceFormOpen, setServiceFormOpen] = useState(false);

  const appInfo = getAppInfo();

  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.md - 1}px)`,
  });

  // Set form entity if pageProps are filled
  useEffect(() => {
    if (!isEmpty(pageProps)) {
      setEntity(
        pageProps.isPage
          ? pageProps.content?.page?.title
            ? pageProps.content?.page
            : pageProps.content?.pc?.[0]
          : pageProps.content?.[pageProps.entity]?.[0],
      );
    }
  }, [pageProps]);

  useEffect(() => {
    if (mobileNavOverlay) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [mobileNavOverlay]);

  if (isFallback) {
    return (
      <div className="loading-page">
        <LoadingSpinner />
      </div>
    );
  }

  // why is page props filled an then not?
  if (isEmpty(pageProps)) {
    // locally because of middleware 404 pages do not get rendered and pageProps are empty
    return (
      <div>
        <h1 className="title mb-4 mt-5 text-center">
          Sorry, this searched page could not be found
        </h1>
        <p className="text-center">Error 404</p>
      </div>
    );
  }

  const rawEntityData = pageProps?.content?.[pageProps.entity];
  const entityData = Array.isArray(rawEntityData)
    ? rawEntityData?.[0]
    : rawEntityData;
  const entityId = entityData?.id;

  const showBreadcrumb =
    (pageProps?.content?.page?.show_breadcrumb || !pageProps?.isPage) ?? false;

  // Remove ui here for better typing
  const forms = cloneDeep(pageProps.forms) as FormContextProps["forms"];

  // check if accessing url:
  const baseHostCheckFailed =
    typeof document !== "undefined" &&
    !document.location.host.includes(publicRuntimeConfig.BASE_URL);

  const labels: LabelContextProps =
    Object.entries(pageProps?.__global?.labels || {}).reduce(
      (acc, [uiId, labelString]) => {
        const label = parseJson(labelString, true);
        if (label) {
          acc[uiId] = label;
        }
        return acc;
      },
      {},
    ) ?? {};

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [emailLabel, contactLabel, chatLabel, serviceLabel] = useLabels(
    labels,
    ["ui-212", "Email"],
    ["ui-613", "Contact"],
    ["ui-619", "Live-Chat"],
    ["ui-956", "Service"],
  );

  return (
    <UserProvider>
      <UserDataContextProvider>
        <LabelContextProvider labels={labels}>
          <FormContextProvider
            formModal={formModal}
            toggleFormModal={toggleFormModal}
            forms={forms}
            setEntity={setEntity}
            entity={entity}
            formCt={formCt}
            setFormCt={setFormCt}
            formCtData={formCtData}
            setFormCtData={setFormCtData}
            serviceFormOpen={serviceFormOpen}
            setServiceFormOpen={setServiceFormOpen}
          >
            <LocaleContextProvider
              locale={locale}
              localeOverlay={localeOverlay}
              setLocaleOverlay={setLocaleOverlay}
            >
              <RoutingContextProvider slugPath={slugPath}>
                <PreloadProvider>
                  <PageHead
                    entity={
                      pageProps.isPage
                        ? pageProps.content?.page
                        : pageProps.content?.[pageProps.entity]?.[0]
                    }
                    header={pageProps.header}
                    pc={pageProps?.content?.pc?.[0]}
                    contactMain={pageProps.footer?.contact_main}
                  />
                  <style jsx global>{`
                    :root {
                      --din-web-pro-font: ${dinWebProFont.style.fontFamily};
                    }
                  `}</style>

                  <div className={classNames("root", dinWebProFont.variable)}>
                    <NavContextProvider
                      isLandingpage={isLandingpage}
                      mobileNavOverlay={mobileNavOverlay}
                      setMobileNavOverlay={setMobileNavOverlay}
                      customerPortalOverlayOpen={customerPortalOverlayOpen}
                      setCustomerPortalOverlayOpen={
                        setCustomerPortalOverlayOpen
                      }
                      showCountryForwardingBanner={showCountryForwardingBanner}
                      setShowCountryForwardingBanner={
                        setShowCountryForwardingBanner
                      }
                    >
                      <CSSTransition
                        in={mobileNavOverlay}
                        timeout={300}
                        classNames="mobile-menu-animate"
                        unmountOnExit
                      >
                        <MobileNavigation
                          root={nav}
                          header={pageProps.header}
                          footer={pageProps.footer?.meta ?? []}
                          related_links={
                            pageProps.footer?.footer_related_links ?? []
                          }
                        />
                      </CSSTransition>

                      {baseHostCheckFailed && (
                        <p
                          style={{
                            background: "red",
                            padding: "10px",
                            textAlign: "center",
                            color: "#fff",
                            fontWeight: "bold",
                          }}
                        >
                          You are accessing this site via{" "}
                          <u>{document.location.host}</u> but configured{" "}
                          <u>{publicRuntimeConfig.BASE_URL}</u> as BASE_URL in
                          .env,
                          <br />
                          that does not match and will result in Link/URL
                          generation errors (probably missing Redirection Proxy
                          Setup). Please fix this.
                        </p>
                      )}
                      <CountryForwarding entityId={entityId} />
                      <MetaNavigation
                        nav={nav}
                        header={pageProps.header}
                        entityId={entityId}
                      />
                      <HeaderNavigation
                        nav={nav}
                        entityId={entityId}
                        header={pageProps.header}
                        content={pageProps.content}
                      />
                      {pageProps.header?.myKrohne && (
                        <CustomerPortalOverlay
                          open={customerPortalOverlayOpen}
                          close={() => setCustomerPortalOverlayOpen(false)}
                          navigation={pageProps?.header?.myKrohne}
                        />
                      )}
                    </NavContextProvider>
                    {showBreadcrumb && (
                      <Breadcrumb
                        crumbs={pageProps.header?.breadcrumb}
                        title={
                          pageProps.content?.[pageProps.entity]?.[0]?.title
                        }
                      />
                    )}
                    <Body>
                      <Component {...pageProps} />
                    </Body>
                    <Footer
                      contact={pageProps.footer?.contact_main}
                      contacts={pageProps.footer?.contacts}
                      currentCountry={pageProps.header?.base?.currentCountry}
                      entityId={entityId}
                      globalBase={pageProps.header?.base}
                      isLandingpage={isLandingpage}
                      meta={pageProps.footer?.meta}
                      nav={nav}
                      related_links={pageProps.footer?.footer_related_links}
                    />
                    <FormModal
                      tabText={pageProps.header?.base?.form}
                      contact={pageProps.footer?.contact_main}
                    />
                    <StickyConversion>
                      {isMobile ? (
                        <StickyItem
                          icon="phone-envelope"
                          action={() => toggleFormModal("fm_contact_general")}
                          text={emailLabel.label}
                          iconSize={26}
                        />
                      ) : (
                        <>
                          <StickyItem
                            key={`sticky-icon-envelope`}
                            icon="envelope"
                            action={() => toggleFormModal("fm_contact_general")}
                            text={emailLabel.label}
                          />
                          <StickyItem
                            key={`sticky-icon-phone`}
                            icon="phone"
                            action={() => toggleFormModal("direct_contact")}
                            text={contactLabel.title_prefix}
                          />
                          {pageProps.header?.base?.livechat_script && (
                            <StickyItem
                              key={`sticky-icon-comment`}
                              hidden={false}
                              icon="comment"
                              action={() => {
                                eval(pageProps.header?.base?.livechat_script);
                              }}
                              text={chatLabel.label}
                            >
                              <ChatModal
                                open={showChatModal}
                                setOpen={setShowChatModal}
                                action={() => {
                                  eval(pageProps.header?.base?.livechat_script);
                                }}
                              />
                            </StickyItem>
                          )}
                          {(appInfo.system !== "live") && (
                            <StickyItem
                              key={`sticky-icon-headset`}
                              icon="headset"
                              action={() => setServiceFormOpen(true)}
                              text={serviceLabel.short_title}
                            />
                          )}
                        </>
                      )}
                    </StickyConversion>
                  </div>
                  <ServiceForm
                    open={serviceFormOpen}
                    setOpen={setServiceFormOpen}
                    action={
                      pageProps.content?.service?.[0]?.service_form_action
                    }
                  />
                  <Tracker
                    trackingScript={pageProps?.header?.base?.tracking_script}
                  />
                </PreloadProvider>
              </RoutingContextProvider>
            </LocaleContextProvider>
          </FormContextProvider>
        </LabelContextProvider>
      </UserDataContextProvider>
    </UserProvider>
  );
};

export default App;
