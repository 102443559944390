import classNames from "classnames";
import { Button } from "components/button";
import { Shape } from "components/shape";
import { ChatModalProps } from "constants/types";
import { useLabels } from "helpers/hooks";
import { FC, useEffect, useRef } from "react";
import { Transition } from "react-transition-group";
import { colors } from "theme/theme";

const ChatModal: FC<ChatModalProps> = ({ open, setOpen, action }) => {
  const [modalTitle, modalDescription, modalButton] = useLabels(
    ["ui-847", "Do you have any Questions?"],
    ["ui-848", "Chat live with our experts."],
    ["ui-849", "Start chat"],
  );

  const doWhenInitialized = () => {
    setTimeout(() => {
      const { availability } = window?.LiveChatWidget?.get("state") || {};
      const wasClosedBefore = Boolean(
        Number(localStorage.getItem("closed_chat_popup")),
      );

      if (availability === "online" && !wasClosedBefore) {
        setOpen(true);
      }
    }, 3000);
  };

  useEffect(() => {
    const isBrowser = typeof window !== "undefined";

    const checkForLiveChat = setInterval(() => {
      if (isBrowser && window?.LiveChatWidget) {
        window.LiveChatWidget.on("ready", doWhenInitialized);
        clearInterval(checkForLiveChat);
      }
    }, 250);

    return () => {
      if (isBrowser && window?.LiveChatWidget) {
        window.LiveChatWidget.off("ready", doWhenInitialized);
        clearInterval(checkForLiveChat);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transitionRef = useRef<HTMLDivElement>(null);

  return (
    <Transition
      in={open}
      nodeRef={transitionRef}
      mountOnEnter
      unmountOnExit
      appear
      timeout={300}
    >
      {(state) => (
        <div
          className={classNames("chat-modal", state && `state-${state}`)}
          ref={transitionRef}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="chat-modal-header">
            <h5 className="chat-modal-title">{modalTitle.title}</h5>
            <div
              onClick={() => {
                localStorage?.setItem("closed_chat_popup", "1");
                setOpen(false);
              }}
            >
              <Shape variant="close" fill={colors.darkBlueHighlight} />
            </div>
          </div>
          <p className="chat-modal-text">{modalDescription.title}</p>
          <Button
            variant="secondary"
            icon="comment"
            label={modalButton.title}
            onClick={() => {
              localStorage?.setItem("closed_chat_popup", "1");
              setOpen(false);
              action();
            }}
          />
        </div>
      )}
    </Transition>
  );
};

export default ChatModal;
