import { CountriesResponse } from "pages/api/countries";
import { getLocale } from "helpers/locale/utils";
import isObject from "lodash/isObject";
import { useLabels } from "helpers/hooks";
import { Ws } from "types";
import ForceLocaleLink from "components/link";
import Image from "next/image";
import Portal from "components/lightbox";
import React, { useEffect, useState } from "react";

interface LanguagePortalProps {
  align?: "center" | "end" | "start";
  currentCountry: Ws;
  entityId: string;
  offset?: number;
  open: boolean;
  pageSlug?: string;
  setOpen: (value: boolean) => void;
  side?: "bottom" | "top";
  trigger: React.RefObject<HTMLDivElement>;
}

const LanguagePortal = ({
  align = "start",
  currentCountry,
  entityId,
  offset = 0,
  open,
  setOpen,
  side = "bottom",
  trigger,
  pageSlug,
}: LanguagePortalProps) => {
  const portal = React.useRef();

  const [triggerRect, setTriggerRect] = useState<DOMRect | null>(null);
  const [entity, setEntity] = useState(currentCountry);

  const [countries, setCountries] = useState<CountriesResponse | null>(null);

  useEffect(() => {
    fetch(`/api/countries?entityId=${entityId}&landingPage=true`)
      .then((res) => res.json())
      .then(setCountries);
  }, [entityId]);

  const [title] = useLabels(["ui-122", "Change language of the website"]);

  function removeLandingPagesPrefix(url) {
    const prefix = "/landingpages/";
    if (url.includes(prefix)) {
      return url.slice(prefix.length);
    }
    return url;
  }

  function isSlugInHostname(hostname, entitySlug) {
    const regex = new RegExp(`\\b${entitySlug}\\b`, "i");
    return regex.test(hostname);
  }

  useEffect(() => {
    if (countries) {
      countries.entities?.find((x) => {
        const hostname = x.website_protocol_and_hostname;
        const entitySlug = pageSlug ? removeLandingPagesPrefix(pageSlug) : "";

        if (entitySlug && isSlugInHostname(hostname, entitySlug)) {
          setEntity(x);
        }
      });
    }
  }, [countries, pageSlug]);

  const formatLabel = (label: string) => {
    if (label.includes("/")) {
      label = label.split("/")[0].trim();
    } else if (label.includes("(")) {
      label = label.split("(")[0].trim();
    }

    return label;
  };

  const hasPath = (language: any): language is { path: string } =>
    isObject(language) &&
    "path" in language &&
    typeof language.path === "string";

  const getPath = (language: any) =>
    hasPath(language) ? language.path : undefined;

  const country = entity?.country?.[0]?.code;
  const defaultLanguage = (entity?.default_language?.[0]?.code || "en").slice(
    0,
    2,
  );

  const defaultPath = getPath(
    entity?.available_language.find(
      (language) => language.code === entity?.default_language?.[0]?.code,
    ),
  );

  const handleClick = (e) => {
    if (
      portal?.current?.contains(e.target) ||
      trigger?.current?.contains(e.target)
    ) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  React.useEffect(() => {
    setTriggerRect(
      trigger.current ? trigger.current.getClientRects()?.[0] : null,
    );
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  React.useEffect(() => {
    setTriggerRect(
      trigger.current ? trigger.current.getClientRects()?.[0] : null,
    );
  }, [open]);

  const customOrder = ["en", "de", "fr", "es", "cs", "pl", "ru", "zh"];

  if (!triggerRect) {
    return null;
  }

  return open ? (
    <Portal selector="language-portal">
      <div
        ref={portal}
        className={`language-portal ${align} ${side}`}
        style={{
          top: `${
            (side === "top"
              ? trigger.current?.offsetTop
              : triggerRect.bottom + window.scrollY) + offset
          }px`,
          left:
            align === "start"
              ? `${triggerRect.left - 12}px`
              : align === "end"
              ? `${triggerRect.right + 12 - triggerRect.height / 2}px`
              : "unset",
          transform: `translate(calc(${align === "end" ? "-100%" : "0px"}), ${
            side === "top" ? "-100%" : "0px"
          })`,
          willChange: "transform",
        }}
      >
        <div className="language-portal-title text-nowrap">{title.label}</div>
        {entity &&
          entity.available_language
            .sort(
              (a, b) =>
                customOrder.indexOf(a.code.slice(0, 2)) -
                customOrder.indexOf(b.code.slice(0, 2)),
            )
            .map((attributeOption, index) => {
              const locale = country
                ? getLocale(
                    country,
                    attributeOption.code.slice(0, 2) || defaultLanguage,
                  )
                : undefined;

              return (
                <ForceLocaleLink
                  className="language-portal-item flex-start-center text-nowrap"
                  locale={locale}
                  href={
                    country
                      ? getPath(attributeOption) ?? defaultPath
                      : entity?.website_protocol_and_hostname
                  }
                  onClick={() => setOpen(false)}
                  key={index}
                >
                  <Image
                    src={`/assets/country-flags/${attributeOption.code.slice(
                      0,
                      2,
                    )}.svg`}
                    alt="dropdown"
                    height={20}
                    width={20}
                  />
                  {formatLabel(attributeOption.label)}
                </ForceLocaleLink>
              );
            })}
      </div>
    </Portal>
  ) : null;
};

export default LanguagePortal;
