import classNames from "classnames";
import { getSpeakingId } from "helpers/text-processing";
import { sanitize } from "isomorphic-dompurify";
import { FC } from "react";
import BootstrapCard from "react-bootstrap/Card";
import { CardProps } from "constants/types"
import { wrapUppercaseWords } from ".";
import { Contact } from "types";
import { Cloudinary } from "components/cloudinary";
import { Shape } from "components/shape";
import { colors } from "theme/theme";
import { useLabels } from "helpers/hooks";

interface ContactCardProps extends CardProps {
  entity: Partial<Contact & { _url?: string; }>;
}

const ContactCard: FC<ContactCardProps> = ({
  entity,
}) => {
  const [vCardButton] = useLabels(["ui-989", "vCard"]);

  const createVCard = (contact) => {
    const sanitize = (value) =>
      value
        ? String(value)
          .replace(/\n/g, "\\n")
          .replace(/,/g, "\\,")
        : "";

    const fields = [
      `BEGIN:VCARD`,
      `VERSION:3.0`,
      `FN:${sanitize(contact.title) || "Unbekannt"}`,
      `N:${sanitize(contact.lastName)};${sanitize(contact.firstName)};;;`,
      contact.short_title && `ROLE:${sanitize(contact.short_title)}`,
      contact.phone && `TEL;TYPE=work,voice:${sanitize(contact.phone)}`,
      contact.email && `EMAIL;TYPE=work:${sanitize(contact.email)}`,
      (contact.address || contact.city || contact.state || contact.zip || contact.country) &&
      `ADR;TYPE=work:;;${sanitize(contact.address)};${sanitize(contact.city)};${sanitize(contact.state)};${sanitize(contact.zip)};${sanitize(contact.country)}`,
      contact.web && `URL:${sanitize(contact.web)}`,
      contact.images?.[0] && `PHOTO;VALUE=URI:${sanitize(contact.images[0])}`,
      contact.fax && `TEL;TYPE=fax:${sanitize(contact.fax)}`,
      `END:VCARD`,
    ]
      .filter(Boolean)
      .join("\n");

    const blob = new Blob([fields], { type: "text/vcard;charset=utf-8" });
    return URL.createObjectURL(blob);
  };

  const handleDownload = (contact) => {
    const vCardUrl = createVCard(contact);
    const link = document.createElement("a");
    link.href = vCardUrl;
    link.download = `${contact.title || "contact"}.vcf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(vCardUrl);
  };

  return (
    <>
      <BootstrapCard
        className="contact-card"
        id={getSpeakingId({ title: entity.title })}
        itemScope
        itemType="https://schema.org/ContactPoint"
      >
        <BootstrapCard.Body>
          <Cloudinary
            media={
              "images" in entity
                ? entity.images?.[0]
                : { digital_asset_id: "im-photo-composition/blue-gradient-triangles" }
            }
            ar="ar11"
            className="contact-image"
          />
          <div className="contact-info">
            {(entity.title || entity.short_title) && (
              <p
                itemProp="name"
                className={classNames(
                  "contact-title h6"
                )}
                dangerouslySetInnerHTML={{
                  __html: wrapUppercaseWords(sanitize(entity.title || entity.short_title), "span"),
                }}
              />
            )}
            {entity.excerpt && (
              <p
                itemProp="contactType"
                className={classNames(
                  "contact-excerpt"
                )}
                dangerouslySetInnerHTML={{
                  __html: wrapUppercaseWords(sanitize(entity.excerpt), "span"),
                }}
              />
            )}
            {entity.phone && (
              <a href={`tel:${entity.phone}`} className="icon-text" itemProp="telephone">
                <Shape variant="phone" fill={colors.primary} />
                {entity.phone}
              </a>
            )}
            {entity.email && (
              <a href={`mailto:${entity.email}`} className="icon-text" itemProp="email">
                <Shape variant="envelope" fill={colors.primary} />
                {entity.email}
              </a>
            )}
            <div onClick={() => handleDownload(entity)} className="icon-text">
              <Shape variant="vcard" fill={colors.primary} />
              {vCardButton.label}
            </div>
          </div>
        </BootstrapCard.Body>
      </BootstrapCard>
    </>

  )
}

export default ContactCard;
