import classNames from "classnames";
import { createElement, FC } from "react";
import { HeadingProps } from "../../constants/types";
const cleanText = (text: string): string => {
  return text.replace(/\s+/g, " ").trim();
};

export const Heading: FC<HeadingProps> = ({
  title,
  level = "h3",
  id,
  stylingLevel,
  decoration = "none",
  subline,
  contentRight,
  contentRightAlign = "auto",
  className,
  alignment,
  margin,
}) => {
  const sublineContainer = subline && <p className="flex-grow-1">{subline}</p>;
  const heading = title
    ? createElement(level, {
        className: classNames(stylingLevel, className),
        dangerouslySetInnerHTML: {
          __html: cleanText(title),
        },
      })
    : null;

  return (
    <div
      className={classNames(
        "heading",
        "d-flex",
        "flex-wrap",
        "info-header",
        "mt-0",
        alignment === "center"
          ? "justify-content-center"
          : "justify-content-between",
        subline ? "align-items-end" : "align-items-center",
        decoration === "underline" && ["border-bottom"],
        margin,
      )}
      id={id}
    >
      <div>
        {heading}
        {sublineContainer}
      </div>
      {contentRight && (
        <div className={`content-right align--${contentRightAlign}`}>
          {contentRight}
        </div>
      )}
    </div>
  );
};
